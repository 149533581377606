import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelectMediaComponent } from './components/select-media/select-media.component';
import { MeetingComponent } from './components/meeting/meeting.component';
import { MeetingControlsComponent } from './components/meeting-controls/meeting-controls.component';
import { MeetingChatComponent } from './components/meeting-chat/meeting-chat.component';
import { MeetingMenuComponent } from './components/meeting-menu/meeting-menu.component';
import { MeetingUserComponent } from './components/meeting-user/meeting-user.component';
import { MeetingSlideControlsComponent } from './components/meeting-slide-controls/meeting-slide-controls.component';
import { JoinComponent } from './components/join/join.component';
import { LoginComponent } from './components/login/login.component';
import { MeetingsComponent } from './components/meetings/meetings.component';
import {CreateMeetingComponent } from './components/create-meeting/create-meeting.component';
import { ParticipantVideoComponent } from './components/participant-video/participant-video.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ParticipantVideoSetupViewComponent } from './components/participant-video-setup-view/participant-video-setup-view.component';
import { EndComponent } from './components/end/end.component';
import { ParticipantAudioComponent } from './components/participant-audio/participant-audio.component';
//import { OrderParticipantsPipe } from './pipes/order-participants.pipe';
//import { FocusedParticipantComponent } from './components/focused-participant/focused-participant.component';
import { EndedComponent } from './components/ended/ended.component';
import { ToastrModule } from 'ngx-toastr';
import { ParticipantsListComponent } from './components/participants-list/participants-list.component';
import { MainGroupListParticipantComponent } from './components/main-group-list-participant/main-group-list-participant.component';
import { MinorGroupListParticipantComponent } from './components/minor-group-list-participant/minor-group-list-participant.component';
import { FilterParticipantsPipe } from './pipes/filter-participants.pipe';
import { LoadingEllipsesComponent } from './components/loading-ellipses/loading-ellipses.component';
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import { UtilService } from './services/util.service';

@NgModule({
  declarations: [
    AppComponent,
    SelectMediaComponent,
    MeetingComponent,
    MeetingControlsComponent,
    MeetingChatComponent,
    MeetingMenuComponent,
    MeetingUserComponent,
    MeetingSlideControlsComponent,
    JoinComponent,
    LoginComponent,
    MeetingsComponent,
    CreateMeetingComponent,
    ParticipantVideoComponent,
    ParticipantVideoSetupViewComponent,
    EndComponent,
    ParticipantAudioComponent,
    //OrderParticipantsPipe,
    //FocusedParticipantComponent,
    EndedComponent,
    ParticipantsListComponent,
    MainGroupListParticipantComponent,
    MinorGroupListParticipantComponent,
    FilterParticipantsPipe,    
    LoadingEllipsesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private injector: Injector){
    //UtilService.injector = this.injector;
  }
}
