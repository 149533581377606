<div>
    <div>
        <label>Username</label>
        <input type="text" [(ngModel)]="username" (keydown.enter)="login()" />
    </div>
    <div>
        <label>Password</label>
        <input type="password" [(ngModel)]="password" (keydown.enter)="login()"/>
    </div>  
    <div>
        <button (click)="login()">Login</button>
    </div>  
</div>