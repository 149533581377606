import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterContentChecked, Host, Optional, AfterViewInit, OnDestroy } from '@angular/core';
import { Participant } from 'src/app/models/Participant';
import { MeetingSettings } from 'src/app/models/MeetingSettings';
import { MeetingComponent } from '../meeting/meeting.component';
import { environment } from '../../../environments/environment';
import { UtilService } from '../../services/util.service';
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';
import { AudioProcessingService } from 'src/app/services/audio-processing.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-participant-video',
  templateUrl: './participant-video.component.html',
  styleUrls: ['./participant-video.component.scss']
})
export class ParticipantVideoComponent implements OnInit, OnChanges, AfterContentChecked, AfterViewInit, OnDestroy {

  //videoEnabled = true;
  //audioEnabled = true;
  //videoAvailable = false;
  //audioAvailable = false;
  //videoStream: MediaStream;
  //audioStream: MediaStream;
  videoConsumer: any;
  
  __participant: Participant;//username will be empty if not using this component during the meeting
  @Input() focus: boolean;
  @ViewChild('video') video: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('volumeMeter') volumeMeter: ElementRef;
  // @ViewChild('audio') audio: ElementRef;
  @Output() videoToggled: EventEmitter<ParticipantVideoComponent> = new EventEmitter<ParticipantVideoComponent>();
  @Output() audioToggled: EventEmitter<ParticipantVideoComponent> = new EventEmitter<ParticipantVideoComponent>();
  @Output() lockToggled: EventEmitter<Participant> = new EventEmitter<Participant>();
  @Output() disconnectUserInitiated: EventEmitter<Participant> = new EventEmitter<Participant>();
  production: boolean;
  visible: boolean = false;
  settingConsumer: any;
  
  streamPreferredSpatialLayer: number;
  streamPriority: number;
  playInterval: any;

  constructor(public settings: MeetingSettings, @Host() @Optional() private meeting: MeetingComponent, public util: UtilService, private meetingHandler: MeetingHandlerService, private elementRef: ElementRef, private route: ActivatedRoute) {
    this.production = environment.production && !route.snapshot.queryParams.debug;
    this.playInterval = setInterval(this.playIntervalHandler.bind(this), 1000);
   }

   @Input()
   set participant(value: Participant){
    this.__participant = value;
    //this.__participant.participantVideoComponent = this;
    if(this.meeting){
      this.meeting.participantVideoComponentsMap.set(this.participant.socketid, this);
    }
    else{
      debugger;
    }
   }

   get participant(){
     return this.__participant;
   }

   get belongsToLocalParticipant(){
    return this.participant.socketid == this.meetingHandler.meParticipant.socketid
   }

  async ngOnDestroy() {
    // if(this.participant.participantVideoComponent == this){
    //   this.participant.participantVideoComponent = null;
    // }
    // else{
    //   //debugger;
    // }
    //if(!this.participant.focused){
    //await this.closeConsumer();
    //}
    //this.__participant.participantVideoComponent = null;//right now, we never actually destroy video components
    clearInterval(this.playInterval);
    if(this.meeting && this.meeting.participantVideoComponentsMap.has(this.participant.socketid)){
      this.meeting.participantVideoComponentsMap.delete(this.participant.socketid);
    }
  }
  async ngAfterViewInit(){
    //this.setStreams();
    
    await this.setStream();
    setTimeout(() => {
      //after it's been added to the 
      this.elementRef.nativeElement.classList.add('no-more-video-component-animations');
      //this.elementRef.nativeElement.style.animation = 'none';
    }, 2000);
  }
  ngAfterContentChecked(): void {
    //console.log('participant changes: ', this.participant);
    //this.setStreams();
  }
  async ngOnChanges(changes: SimpleChanges) {
    //throw new Error('Method not implemented.');
    if(this.participant){
      this.participant.focused = this.focus;
    }
    if(this.participant && this.participant.focused){
      console.log('focus: ', this.focus, ', participant changes: ', this.participant);
    }
    //this.setStreams();
    //this.participant.participantVideoComponent = this;
    if(this.participant && this.video){//only the focused participant component should have changes to the participant
      await this.setStream();
    }
    else{
      //debugger;
    }
  }

  ngOnInit(): void {
    //this.participant.participantVideoComponent = this;
    this.participant.focused = this.focus;

    
    //this.video.nativeElement.muted = true;
    //console.log('participant changes: ', this.participant);
    
  }

  async setStream(){
      if (this.participant.videoProducerId && (!this.videoConsumer || this.videoConsumer.closed || this.videoConsumer.paused || (this.videoConsumer.producerId != this.participant.videoProducerId))) {
      // if(this.videoConsumer){
      //   await this.closeConsumer();
      // }
      this.visible = false;
      console.log('participant changes: ', this.participant);
      let consumer;
      if(this.meetingHandler.videoConsumersMap.has(this.participant.videoProducerId)){
        consumer = this.meetingHandler.videoConsumersMap.get(this.participant.videoProducerId);
      }
      if(consumer && !consumer.closed){
        this.videoConsumer = consumer;
      }
      else
      { 
        if(!this.settingConsumer){
          this.settingConsumer = true;
          try{
            this.videoConsumer = (await this.meetingHandler.createConsumer(this.participant.socketid, this.participant.videoProducerId, 'video', true)).consumer;
            //TODO: we should keep checking the track in this video consumer to see if it has stalled for more than 5 seconds. if it has, then we should try to pause and resume it
            
          }
          finally{
            this.settingConsumer = false;
          }
        }
        else{
          return;
        }
      }
      
      //debugger;
      let replacestream = true;
      if(this.video.nativeElement.srcObject){
        let currentVideoTrack = this.video.nativeElement.srcObject.getVideoTracks()[0];
        if(currentVideoTrack && currentVideoTrack.id == this.videoConsumer.track.id){
          replacestream = false;
        }
      }

      if(replacestream){
        this.video.nativeElement.srcObject = new MediaStream([this.videoConsumer.track]);
      }
      this.participant.videoAvailable = true;
      const mythis = this;
      this.video.nativeElement.onplaying = function(){
        mythis.visible = true;
        mythis.video.nativeElement.onplaying = null;
      }
      
      //await this.meeting.resumeConsumer(this.participant.socketid, this.videoConsumer);

      // if(this.participant.focused){
      //   this.meeting.focusedParticipantComponent.video.nativeElement.srcObject = this.video.nativeElement.srcObject;
      // }
    }
    else if(!this.participant.videoProducerId && this.videoConsumer){
      //this.meeting.closeConsumer(this.participant.socketid, this.videoConsumer);
      //await this.closeConsumer();
      this.visible = false;
      this.video.nativeElement.srcObject = null;
      this.participant.videoAvailable = false;
      this.videoConsumer = null;
      // if(this.participant.focused){
      //   this.meeting.focusedParticipantComponent.video.nativeElement.srcObject = null;
      // }
    }
    else{
      //debugger;
      //await this.meeting.resumeConsumer(this.participant.socketid, this.videoConsumer);
      //console.log('here');
    }
    
  }

  // async closeConsumer(){
  //   if(this.videoConsumer){
  //     await this.meetingHandlerService.closeConsumer(this.participant.socketid, this.videoConsumer);
  //   }
  // }

  // async pauseConsumer(){
  //   if(this.videoConsumer){
  //     await this.meetingHandlerService.pauseConsumer(this.participant.socketid, this.videoConsumer);
  //   }
  // }

  toggleVideoEnabled(){
    //this.videoEnabled = !this.videoEnabled;
    if(this.settings.host){
      this.videoToggled.emit(this);
    }
  }

  toggleAudioEnabled(){
    //this.audioEnabled = !this.audioEnabled;
    if(this.settings.host){
      this.audioToggled.emit(this);
    }
  }

  toggleUserLocked(){
    if(this.settings.host){
      this.lockToggled.emit(this.participant);
    }
  }

  dropHand(){
    if(this.settings.host){
      this.meeting.dropParticipantHand(this.participant);
    }
  }

  initiateDisconnectUser(){
    if(this.settings.host){
      this.disconnectUserInitiated.emit(this.participant);
    }
  }

  scrolledIntoView(completelyVisible: boolean){
    if(this.elementRef?.nativeElement && this.meeting.participantsdiv?.nativeElement){
    //return this.util.isScrolledIntoView(this.container.nativeElement, this.meeting.participantsdiv.nativeElement, 200);
      return this.util.isVisible(this.elementRef.nativeElement, this.meeting.participantsdiv.nativeElement, completelyVisible);
    }
    else{
      return false;
    }
  }

  setVolumeLevel(volumeLevel: number){
    if(!this.participant.data){
      this.participant.data = {};
    }
    this.participant.data.volumeLevel = volumeLevel;

    if(!this.participant.audioAvailable || !volumeLevel || volumeLevel < AudioProcessingService.minimumAudioLevelOfNote){//if it's very low or audio is not enabled, don't show it at all
      this.volumeMeter.nativeElement.style.width = '0px';
    }
    else{
      this.volumeMeter.nativeElement.style.width = `${volumeLevel}%`;
    }
    //[ngStyle]="{width: (!participant.audioAvailable || !participant.data?.volumeLevel ? 0 : participant.data?.volumeLevel) + '%', 'max-width': '100%'}"
  }

  playIntervalHandler(){
    //on mobile, audio/video may have been stopped because the user left the application and played audio from a different application
    if(this.video?.nativeElement.srcObject && this.video.nativeElement.paused && !this.video.nativeElement.ended){
      this.video.nativeElement.play();
    }
  }

  // setVideoStream(stream){
  //   this.videoStream = stream;
  //   this.displayVideo();
  // }

  // setAudioStream(stream){
  //   this.audioStream = stream;
  //   this.displayAudio();
  // }
}
