export class MeetingLite{
    //domain: MeetingDomain = null;
    meetingId = '';
    meetingURL = '';
    title = '';
    ended = false;
    started = false;
    //domainReady = false;
    startParticipantsMuted = false;
    mode = '';
    maxParticipants = 0;
    //maxMeetingModeParticipants = 0;
    attendanceInterval = 0;
    takeAttendance = false;
    allowPublicAccess = false;
    maxParticipantsPerServer = 0;
    allowNonHostsRecord = true;
}

export class MeetingDomain{
    domain: string = '';
    port: number = 0;
    // userCount: number = 0;
    // dead = false;
}