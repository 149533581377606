import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-ellipses',
  templateUrl: './loading-ellipses.component.html',
  styleUrls: ['./loading-ellipses.component.scss']
})
export class LoadingEllipsesComponent implements OnInit, OnDestroy {

  public i: number = 0;
  //numbers: number[];
  //ellipsesstring = '';
  interval;
  constructor() { }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.i++;
      if(this.i > 3){
        this.i = 0;
      }
      //this.numbers = Array(this.i).fill(0).map((x,i)=>i);

      // this.ellipsesstring = '';
      // let x = 0;
      // for(; x < this.i; x++){
      //   this.ellipsesstring += '.';
      // }
      // for(; x < 3 - x; x++){
      //   this.ellipsesstring += '&nbsp';
      // }
      
    }, 1000);
  }

}
