import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingService } from 'src/app/services/meeting.service';

@Component({
  selector: 'app-create-meeting',
  templateUrl: './create-meeting.component.html',
  styleUrls: ['./create-meeting.component.scss']
})
export class CreateMeetingComponent implements OnInit {

  publicaccess:boolean = true;
  title:string = '';
  mode:string = '';
  starttime:any;
  constructor(private router: Router, private activatedroute: ActivatedRoute, private meetingService: MeetingService) {
   
   }

  ngOnInit(): void {
    let date = new Date();
    //let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
    date.setMinutes(date.getMinutes() + 5);
    this.starttime = date;
  }

  async createmeeting(){
    //debugger
      let user = localStorage.getItem(`vCirlce-User`);
      let meeting = await this.meetingService.createMeeting(JSON.parse(user).jwt,this.title, this.mode,this.publicaccess, this.starttime).toPromise();
      this.router.navigate(['/meetings']);
  }

  getMeetings(){

  }
}