import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';
import { ParticipantsListComponent } from '../participants-list/participants-list.component';

@Component({
  selector: 'app-minor-group-list-participant',
  templateUrl: './minor-group-list-participant.component.html',
  styleUrls: ['./minor-group-list-participant.component.scss']
})
export class MinorGroupListParticipantComponent implements OnInit {

  @Input() username;
  @Input() room;
  @Output() removeParticipant: EventEmitter<any> = new EventEmitter<any>();
  constructor(public meetingHandler: MeetingHandlerService) { 
  }

  ngOnInit(): void {
  }

  remove(){
    // this.participantlist.moveParticipantToMainRoom(this.room, this.username);
    this.removeParticipant.emit({username: this.username, room: this.room});
  }

}
