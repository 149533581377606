import { Component, Host, Input, OnInit } from '@angular/core';
import { Participant } from 'src/app/models/Participant';
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';
import { ParticipantsListComponent } from '../participants-list/participants-list.component';

@Component({
  selector: 'app-main-group-list-participant',
  templateUrl: './main-group-list-participant.component.html',
  styleUrls: ['./main-group-list-participant.component.scss']
})
export class MainGroupListParticipantComponent implements OnInit {
  selected: boolean;
  participant: Participant;
  @Input() localParticipant: boolean;
  @Input() username;
  
  //defaultParticipant: Participant = new Participant();
  
  constructor(@Host() public participantlist: ParticipantsListComponent, public meetingHandler: MeetingHandlerService) {
    //this.defaultParticipant.name = '-- Missing --';
  }

  ngOnInit(): void {
  }

  

}
