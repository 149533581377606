<div class="participantcontainer">
    <div class="participantvideodiv">
        <video class="participantvideo localparticipant" playsinline autoplay muted #video></video>
        <audio #audio></audio>
        <!-- <div>Volume level: {{volumeLevel}}</div> -->
        <!-- <input  type="range" #audioVolume [attr.value]="this.volumeLevel" class="volume-meter w-100" disabled max="100"> -->
        <div class="videosettingsdiv">
            <button *ngIf="!this.inMeeting" [ngClass]="['enableaudiobutton', 'fa', audioEnabled ? 'null' : 'bg-grey', audioEnabled ? 'fa-microphone' : 'fa-microphone-slash']" (click)="toggleAudioEnabled()"></button>
            <button *ngIf="!this.inMeeting" [ngClass]="['enablevideobutton', 'fa', videoEnabled ? 'null' : 'bg-grey', videoEnabled ? 'fa-video' : 'fa-video-slash']" (click)="toggleVideoEnabled()"></button>
        </div>
        <div class="volume-meter" #volumeMeter></div>
    </div>
    <!-- <div class="volume-meter-background" [hidden]="this.volumeLevel === undefined"> -->
        
    <!-- </div> -->
</div>


