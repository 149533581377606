import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MeetingService } from 'src/app/services/meeting.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  company = '';
  username = '';
  password = '';
  constructor(private router: Router, private activatedroute: ActivatedRoute, private meetingservice: MeetingService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  async login(){  
      let user = await this.meetingservice.login(this.username, this.password).toPromise().catch((error) => {
        console.error(error);
        this.toastr.error('Login Error. Please confirm your username and password');
      });
      //debugger;
      if(user){
        window.localStorage.setItem(`vCirlce-User`, JSON.stringify(user));
        this.router.navigate(['/meetings']);
      }
  }
}