import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IframeHandlerService {

  static userJoinedMessageId: string = 'user-joined'
  static userLeftmessageId: string = 'user-left';
  static meetingEndedMessageId: string = 'meeting-ended';
  static participantAddedMessageId: string = 'participant-added';
  static participantRemovedMessageId: string = 'participant-removed';

/*
'user-joined' //when the user has successfully joined the meeting
'user-left' //when the user leaves the meeting
'meeting-ended' //when a host has ended the meeting
'participant-added' //when another user has been added/joined (supplies participant info as additional data)
'participant-removed' //when another user has been removed/left (supplies participant info as additional data)
 */

  constructor(public router: Router) {
    router.events.pipe(
       //filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
       filter(event => event instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => {
      //console.log('location', e);

      //e.urlAfterRedirects.startsWith('/join')
    });

    window.addEventListener('message', (event) => {
      const message = event.data;
    });
  }

  postMessageToParent(message: {messageId: string, data?: any}){
    //console.log('posting message: ', message.messageId, 'data: ', message.data);
    if(window != window.parent && window.parent){
      parent.postMessage(message, '*');
    }
   
  }
}
