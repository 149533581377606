import { Injectable } from '@angular/core';
import { LocalRecordingEventData, LocalRecordingService } from './local-recording.service';
import { ToastrService } from 'ngx-toastr';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class LocalRecordingEventsService {
  recordingProcessingProgress: number = 0;
  recordOnJoin = false;
  constructor(private recordingService: LocalRecordingService, private toastr: ToastrService) { 
    this.recordingService.recordingEventSubject.subscribe((data: LocalRecordingEventData) => {
      const {eventId, eventMessage} = data;
      switch(eventId){
        case 'recordingerror':
          this.toastr.error(eventMessage);
          break;
        case 'recordingstarted':
          this.toastr.success(eventMessage);
          break;
        case 'recordingprocessingerror':
          this.toastr.error(eventMessage);
          $('#recordingModal').modal('hide');
          break;
        case 'alreadyrecording':
          this.toastr.warning(eventMessage);
          break;
        case 'recordingprocessingfinished':
          this.toastr.success(eventMessage);
          $('#recordingModal').modal('hide');
          this.recordingProcessingProgress = 0;
          break;
        case 'recordingprocessingstarted':
          $('#recordingModal').modal('show');
          break;
        case 'recordingstoppedabruptly':
          this.toastr.warning(eventMessage);
          break;
        case 'recordingnearingmaxsize':
          if(!this.recordingService.alreadyEndingRecording){
            this.toastr.warning('The recording has reached its maximum size. Please start another recording');
            this.recordingService.endRecordMeeting(true);
          }
          break;
        case'recordingsaveerror':
          this.toastr.error(eventMessage);
          this.recordingService.endRecordMeeting(false);//at this point, we'll do our best to salvage the video
          break;
        default:
      }
    });
    this.recordingService.processingProgressSubject.subscribe((progress: number) => {
      this.recordingProcessingProgress = Math.round(progress);
    });
  }
}
