import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
//import { AudioProcessingService } from './audio-processing.service';
import fixWebmDuration from 'fix-webm-duration';
//import { FFmpeg } from '@ffmpeg/ffmpeg';
//import { fetchFile } from '@ffmpeg/util';
// declare const FFmpegUtil: any;
// declare const FFmpegWASM: any;

import { FFmpeg } from '@diffusion-studio/ffmpeg-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AudioProcessingService } from './audio-processing.service';
import { MeetingHandlerService } from './meeting-handler.service';

// const { fetchFile } = FFmpegUtil;
// const { FFmpeg } = FFmpegWASM;

@Injectable({
  providedIn: 'root'
})
export class LocalRecordingService {

  endingGracefuly = true;
  meetingRecordingStream: MediaStream;
  recorder: MediaRecorder;
  recording = false;
  microphoneRecordingSource: MediaStreamAudioSourceNode;
  screenShareRecordingSource: MediaStreamAudioSourceNode;
  recordingFileWritableStream: FileSystemWritableFileStream;
  desktopVideoTrack: MediaStreamTrack;
  //desktopAudioTrack: MediaStreamTrack;
  recordingAudioContext = new AudioContext();
  recordingAudioStreamDestination: MediaStreamAudioDestinationNode;
  recordingFileHandle: FileSystemFileHandle;
  //ffmpegRef = new FFmpeg();
  ffmpeg: FFmpeg;
  //recordingBlobs: Blob[] = [];
  ffmpegLoadPromise: Promise<void>;
  recordingDuration: number = 0;
  processingProgressSubject: Subject<number> = new Subject<number>();
  recordingEventSubject: Subject<LocalRecordingEventData> = new Subject<LocalRecordingEventData>();
  alreadyEndingRecording = false;
  recordingProcessingProgress: number = 0;
  startRecordingImmediately = false;
  totalRecordingSize: number = 0;
  //i tested with a 2GB max file size, and ffmpeg couldn't process the file, so I've reduced it to 1.5GB
  maxRecordingSize: number = environment.production ? 1.5 * 1024 * 1024 * 1024 : 1.5 * 1024 * 1024 * 1024;
  //meetingHandlerService: MeetingHandlerService;

  constructor(private audioProcessingService: AudioProcessingService, private meetingHandlerService: MeetingHandlerService) { 
    try{
      this.ffmpegLoadPromise = this.loadFFMpeg();
    }
    catch(e){
      console.log('Unable to load ffmpeg. Error: ', e)
    }
    // window.onbeforeunload = (e) => {
    //   //return 'jhjhjh';
    //   e.returnValue = 'onbeforeunload';
    // };
    window.addEventListener('beforeunload', (e) => {
      console.log('beforeunload', e);
      if(this.recording){
        this.endRecordMeeting(false);
        //we can't use the popup in onbeforeunload because the unload event is unreliable. 
        //This may be our last chance to ensure the user's recording isn't entirely lost
        //e.returnValue = 'onbeforeunload';
      }
    });
    window.addEventListener('unload', () => {
      if(this.recording){
        this.endRecordMeeting(false);
      }
    });
    setInterval(() => {
      //for some reason, sometimes, ondataavailable refuses to fire. This is an atempt to force it
      if(this.recorder && this.recorder.state == 'recording'){
        this.recorder.requestData();
      }
    }, 1000);
    //no need to unregister these listeners
  }

  get mimeType(){
    return MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';
  }

  async aquireRecordingFileHandle(){
    this.recordingFileHandle = await window.showSaveFilePicker({suggestedName: 'Recording.mp4',
                                                        types: [
                                                          {
                                                            description: "Video Recording File",
                                                            accept: { "video/mp4": [".mp4"] },
                                                          },
                                                        ], });

      // create a FileSystemWritableFileStream to write to
    this.recordingFileWritableStream = await this.recordingFileHandle.createWritable();
  }

  async aquireScreenShareStream(){
    const options: any = {video: true};
    options.preferCurrentTab = true;
    //options.displaySurface = 'window';
    this.meetingRecordingStream = await navigator.mediaDevices.getDisplayMedia(options);
  }

  async recordMeeting(){
    //if the we are not merging output streams on this platform, then we can't record
    if(!this.audioProcessingService.mergeOutputAudioStreams){
      return;
    }

    if(this.recording){
      console.error('Already recording');
      this.recordingEventSubject?.next({eventId: 'alreadyrecording', eventMessage: ''});
      return;
    }

    this.recordingProcessingProgress = 0;
    this.totalRecordingSize = 0;
    
    this.endingGracefuly = false;

    if(!this.meetingRecordingStream){
      throw new Error('Recording stream not found');
    }

    this.desktopVideoTrack = this.meetingRecordingStream.getVideoTracks()[0];

    if(!this.desktopVideoTrack || !this.meetingRecordingStream.active){
      throw new Error('Recording stream not found or has been stopped');
    }
    //this.desktopAudioTrack = this.meetingRecordingStream.getAudioTracks()[0];

    this.desktopVideoTrack.onended = () => {
      console.log('Video ended...stopping recorder...');
      this.recorder.stop();
    }

    try{

      // if(this.desktopAudioTrack){
      //   this.mergeRecordingAudio(this.desktopAudioTrack);
      // }
      //this would have already been published or will be published when audio is published
      // if(microphoneAudioStream){
      //   this.audioProcessingService.mergeRecordingAudio(microphoneAudioStream);
      // }
      
      this.recordingAudioContext.resume();
      
      
      //we have have to use a new audio stream that is a combination of the main audio stream and the microphone audio stream
      //the microphone audio stream will be merged in when the user enables his mic
      //that may have happened before the recording starts or during the recording
      this.mergeAudioTrack(this.audioProcessingService.remoteMediaStreamDestination.stream.getAudioTracks()[0]);

      //There is a problem here. the steam is essentially paused while there is no audio data being fed in
      //so, the recording will skip parts where absolutely noone has their mic on
      //this is fine except where someone is screen sharing but not speaking
      //this will be "fixed" when we implement audio from screen sharing

      let audioTrack = this.recordingAudioStreamDestination.stream.getAudioTracks()[0];
      //if(desktopAudioTrack || microphoneAudioStream){
        
        
      this.meetingRecordingStream = new MediaStream([this.desktopVideoTrack, audioTrack]);

      //await this.aquireRecordingFileHandle();
      if(!this.recordingFileHandle){
        throw new Error('Recording file handle not found');
      }
      //}

      this.recorder = new MediaRecorder(this.meetingRecordingStream, {mimeType: this.mimeType});
      this.recorder.ondataavailable = (e) => {
        if(e.data.size > 0){
          console.log('writing recording data to file');
          try{
              this.recordingFileWritableStream.write(e.data);
            }
          catch(error){
            console.error(error);
            this.recordingEventSubject.next({eventId: 'recordingsaveerror', eventMessage: 'An error occured while saving your recording. Your disk may be nearing capacity'});
            return;
          }
          this.totalRecordingSize += e.data.size;

          if (this.totalRecordingSize >= this.maxRecordingSize * .95){
            this.recordingEventSubject.next({eventId: 'recordingnearingmaxsize', eventMessage: 'The recording is almost at maximum supported size'});
          }
        }
        //this.recordingBlobs.push(e.data);
        // if(!firstBlob){
        //   firstBlob = new Blob([e.data], {type: 'video/webm'});
        // }
      }
      this.recorder.onstop = async () => {
        const recordingEndTime = Date.now();
        this.recordingDuration = recordingEndTime - recordingStartTime;

          try{
            await this.recordingFileWritableStream.close();
            if(!this.endingGracefuly){//we will expect this to be set when the stop button or the leave button is clicked
              //this.toastr.warning('Recording Stopped');
              this.recordingEventSubject?.next({eventId: 'recordingstoppedabruptly', eventMessage: `Recording was stopped abruptly. The unprocessed recording file saved to ${this.recordingFileHandle.name}.`});
            }
            else{
              //this.toastr.warning('Please wait while we finish processing your video');
              this.recordingEventSubject?.next({eventId: 'recordingprocessingstarted', eventMessage: 'Please wait while we finish processing your recording...'});
              try{
                const recordedFile = await this.recordingFileHandle.getFile();
                await this.fixRecordingTimeStamps(this.recordingDuration, recordedFile);
                this.recordingEventSubject?.next({eventId: 'recordingprocessingfinished', eventMessage: `Recording file saved to ${this.recordingFileHandle.name}.`});
                this.recordingFileHandle = null;
                this.recordingFileWritableStream = null;
                //this.toastr.success('Recording saved');
              }
              catch(error){
                this.recordingEventSubject?.next({eventId: 'recordingprocessingerror', eventMessage: 'There was an error while processing your recording.'});
                console.error(error);
              }
            }
          }
          catch(error){
            console.error(error);
            this.recordingEventSubject?.next({eventId: 'recordingprocessingerror', eventMessage: 'There was an error while saving or processing your recording.'});
            
            //this.toastr.error('Error: Unable to save recorded file');
          }
          finally{
            this.recording = false;
            this.alreadyEndingRecording = false;
            this.recordingProcessingProgress = 0;
          }
        }
      this.recorder.start(1000);
      const recordingStartTime = Date.now();
      //let firstBlob: Blob;
      this.recording = true;
      this.recordingEventSubject?.next({eventId: 'recordingstarted', eventMessage: 'Recording in Progress...'});
    }
    catch(error){
      console.error(error);
      this.recordingEventSubject?.next({eventId: 'recordingerror', eventMessage: 'Sorry, an error occured, and we couldn\'t record the video. Please try again.'});
      this.stopRecordingTracks();
      // if(this.meetingRecordingStream){
      //   this.meetingRecordingStream
      // }
    }
  }

  stopRecordingTracks(){
    if(this.desktopVideoTrack){
      this.desktopVideoTrack.stop();
    }
    // if(this.desktopAudioTrack){
    //   this.desktopAudioTrack.stop();
    // }
  }

  endRecordMeeting(graceful: boolean){
    if(this.alreadyEndingRecording){
      return;
    }
    this.alreadyEndingRecording = true;
    this.endingGracefuly = graceful;
    if(!this.meetingRecordingStream){
      console.error('No meeting recording stream found');
      this.recordingEventSubject?.next({eventId: 'recordingerror', eventMessage: 'No meeting recording stream found.'});
    }
    else
    {
      this.recorder.stop();
      this.stopRecordingTracks();
    }
  }

  addScreenShareAudio(audioTrack: MediaStreamTrack){
    this.removeScreenShareAudio();
    this.screenShareRecordingSource = this.mergeAudioTrack(audioTrack);
  }

  addMicrophoneAudio(audioTrack: MediaStreamTrack){
    this.removeMicrophoneAudio();
    this.microphoneRecordingSource = this.mergeAudioTrack(audioTrack);
  }

  removeScreenShareAudio(){
    if(this.screenShareRecordingSource){
      this.screenShareRecordingSource.disconnect();
    }
  }

  removeMicrophoneAudio(){
    if(this.microphoneRecordingSource){
      this.microphoneRecordingSource.disconnect();
    }
  }

  private mergeAudioTrack(track: MediaStreamTrack) {
    if(!this.recordingAudioStreamDestination){
      this.recordingAudioStreamDestination = this.recordingAudioContext.createMediaStreamDestination();
    }
    
    const source = this.recordingAudioContext.createMediaStreamSource(new MediaStream([track]));
    //const voiceGain = this.recordingAudioContext.createGain();
    //voiceGain.gain.value = 1;
    source.connect(this.recordingAudioStreamDestination);

    this.recordingAudioContext.resume();

    return source;
  }

  async fixRecordingTimeStamps(duration, buggyBlob: Blob){
    await this.ffmpegLoadPromise;
    const buggyBlobUrl = URL.createObjectURL(buggyBlob);
    const fileExt = this.mimeType == 'video/webm' ? 'webm' : 'mp4';
    await this.ffmpeg.writeFile(`input.${fileExt}`, buggyBlobUrl);
    console.log('input file written');

    //we can't use -c:v copy for the audio because the audio is in opus which appears not to be supported in a lot of players
    //i havent's spent a lot of time on this so I could be wrong
    await this.ffmpeg.exec(['-i', `input.${fileExt}`, '-c:v', 'copy', '-max_muxing_queue_size', '9999', 'output.mp4']);
    const outputFileData = this.ffmpeg.readFile("output.mp4");
    console.log('output file length: ', outputFileData.length);
    this.recordingFileWritableStream = await this.recordingFileHandle.createWritable();
    await this.recordingFileWritableStream.write(new Blob([outputFileData.buffer]));
    await this.recordingFileWritableStream.close();
    console.log('finished processing file');
  }

  async loadFFMpeg(){
    this.ffmpeg = new FFmpeg({
    });

    this.ffmpeg.onProgress((progress: number) => {
      //console.log(progress);
    });
    this.ffmpeg.onMessage((message: string) => {
      //console.log(message);
      if(message.startsWith('frame=')){
        const timeEqualsIndexStart = message.indexOf('time=');
        const timeString = message.substring(timeEqualsIndexStart + 5, timeEqualsIndexStart + 5 + 8);
        //console.log(timeString);
        const timeStringDate = new Date('1970-01-01T' + timeString + 'Z');
        const durationSoFar = timeStringDate.getTime();
        if(this.recordingDuration){
          const progress = durationSoFar / this.recordingDuration * 100;
          if(!isNaN(progress) && isFinite(progress)){
            this.processingProgressSubject.next(progress);
            this.recordingProcessingProgress = Math.round(progress);
          }
          console.log(progress);
        }
        else{
          console.warn('Unknown progress');
        }
      }
    })

    return new Promise<void>(async (resolve, reject) => {
      this.ffmpeg.whenReady(async () => {
        await this.ffmpeg.exec(['-help']);
        resolve();
      });
    });
  }

  canRecord(){
    //only top level documents can get a file handle and record. Also, recording uses the merged audio streams so if we are not merging audio streams, then we can't record
    return !!navigator?.mediaDevices?.getDisplayMedia && !!window.showSaveFilePicker && (!window.parent || window.parent == window) && this.audioProcessingService.mergeOutputAudioStreams;
  }

  async destroy(){
    if(this.recording){
      this.endRecordMeeting(false);
    }
    this.recordingAudioContext = new AudioContext();
    this.recordingAudioStreamDestination = null;
    //this.processingProgressSubject = new Subject<number>();
    //this.recordingEventSubject = new Subject<RecordingEventData>();
    this.recording = false;
    this.alreadyEndingRecording = false;

    // if(this.recordingFileWritableStream){
    //   try{
    //     await this.recordingFileWritableStream.close();
    //   }
    //   catch(error){
    //     console.error(error);
    //   }
    // }
  }
}

export class LocalRecordingEventData{
  eventId: eventId
  eventMessage: string;
}

export type eventId = 'recordingerror' | 'recordingstarted' | 'recordingprocessingerror' | 'recordingprocessingfinished' | 'recordingprocessingstarted' | 'recordingstoppedabruptly' | 'alreadyrecording' | 'recordingnearingmaxsize' | 'recordingsaveerror';

