<div class="container">
        <div class="row mb-3 mt-5">
            <label class="col-md-3 form-label" for="title">Title:</label>
            <input class="col-md-4 form-control" id="title" type="text" [(ngModel)]="title"/>
        </div>
        <div class="row mb-3">
            <label class="col-md-3 form-label" for="mode">Mode:</label>
            <select class="col-md-4 form-control" id="mode" type="text" [(ngModel)]="mode">
                <option value="meetingmode">Meeting Mode</option>
                <option value="focusmode">Lecture Mode</option>
            </select>
        </div>
        <div class="row mb-3">
            <label class="col-md-3 form-label" for="starttime">Start Time:</label>
            <input class="col-md-4 form-control" type="datetime-local" [(ngModel)]="starttime" placeholder="Date Time">
        </div>
        <div class="row mb-3">
            <label class="col-md-3 form-check-label" for="public-access">Allow Public Access: </label>
            <input id="public-access" type="checkbox" [(ngModel)]="publicaccess"/>
        </div>

        <button class="offset-md-3" (click)="createmeeting()">Schedule Meeting</button>
</div>