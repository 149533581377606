<div class="container mt-5">    
    <div class="row mb-2" *ngFor="let meeting of meetings;">
        <lable class="col-3 ml-2">{{meeting.Title}}</lable>
        <input class="col-6" type="text" [(ngModel)]="meeting.Url" disabled/>
        <button class="ml-2" type="button" (click)="joinMeeting(meeting.MeetingId)">Join</button>
        <button class="ml-2" type="button" (click)="copyMessage(meeting.Url)">Copy</button>
        <button class="ml-2" type="button" (click)="cancelMeeting(meeting.MeetingId)">Cancel</button>
    </div>     
    
    <div>        
        <button type="button" (click)="createMeeting()">Schedule Meeting</button>
    </div>
</div>
