import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectMediaComponent } from './components/select-media/select-media.component';
import { LoginComponent } from './components/login/login.component';
import { CreateMeetingComponent } from './components/create-meeting/create-meeting.component';
import { MeetingComponent } from './components/meeting/meeting.component';
import { MeetingsComponent } from './components/meetings/meetings.component';
import { EndComponent } from './components/end/end.component';
import { EndedComponent } from './components/ended/ended.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'meetings', component: MeetingsComponent },
  {path: 'create-meeting', component: CreateMeetingComponent},
  { path: 'join/:meetingid', component: SelectMediaComponent },
  { path: 'meeting/:meetingid', component: MeetingComponent, children: [
    { path: 'settings', component: SelectMediaComponent, data: { inMeeting: true} }
  ] },
  { path: 'ended/:meetingid', component: EndedComponent },
  { path: 'end/:meetingid', component: EndComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
