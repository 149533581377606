import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Participant } from '../../models/Participant';
import { MeetingService } from '../../services/meeting.service';
import { MeetingHandlerService, NotificationMessages } from '../../services/meeting-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MeetingDomain, MeetingLite } from 'src/app/models/Meeting';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingSettings } from 'src/app/models/MeetingSettings';
import { ParticipantVideoComponent } from '../participant-video/participant-video.component';
import { MeetingChatComponent } from '../meeting-chat/meeting-chat.component';
import { environment } from '../../../environments/environment';
import { ParticipantAudioComponent } from '../participant-audio/participant-audio.component';
//import { FocusedParticipantComponent } from '../focused-participant/focused-participant.component';
//import { OrderParticipantsPipe } from 'src/app/pipes/order-participants.pipe';
import { SlicePipe } from '@angular/common';
import { UtilService } from 'src/app/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import {Device} from 'mediasoup-client';
import { Console } from 'console';
import { SelectMediaComponent } from '../select-media/select-media.component';
import { AudioProcessingService } from 'src/app/services/audio-processing.service';
import { LocalRecordingEventData, LocalRecordingService } from 'src/app/services/local-recording.service';
import { LocalRecordingEventsService }  from 'src/app/services/local-recording-events.service';
import { Subject, timer } from 'rxjs';
import { ParticipantsListComponent } from '../participants-list/participants-list.component';
import { IframeHandlerService } from 'src/app/services/iframe-handler.service';
import { map, takeWhile } from 'rxjs/operators';
//import { DialogButtonInfo } from 'src/app/models/DialogButtonInfo';


declare const $: any;

//declare const MediasoupClient: any;

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class MeetingComponent implements OnInit, OnDestroy, AfterViewInit {
  
  
  //participantsArray: Participant[] = [];
  //focusedParticipant: Participant;
  //pageparticipants: Participant[] = [];
  
  
  takeAttendance: boolean = false;
  attendanceInterval: number = 0;
 
  //reconnecting2 = false;
  participantsPerShowMore: number = 20;
  maxDisplayedParticipants: number = 51;
  //pages: number[] = [];
  participantsPageNo: number = 1;
  participantsNumPages: number;
  //participantStartIndex: number;
  //participantEndIndex: number;
  //pageChangeTimeout: any;
  //localstream: MediaStream;
  meParticipantReservedOrder = 2;
  
  recalculateParticipantPagesFunction;
  
  centredivwidth = '100px';
  gridModeWidth= '100px';
  gridModeHeight = '100px';
  centredivheight: string = null;

  chatopen = false;

  confirmModalNoCallback: any;
  confirmModalYesCallback: any;
  confirmModalCaption = 'Confirm';
  confirmModalBody = 'Are you sure?';
  confirmModalYesButtonStyle: string = 'btn-primary';
  confirmModalNoButtonStyle: string = 'btn-secondary';
  confirmModalYesText = 'Yes';
  confirmModalNoText = 'No';

  participantVideoComponentsMap: Map<string, ParticipantVideoComponent> = new Map<string, ParticipantVideoComponent>();
  participantAudioComponentsMap: Map<string, ParticipantAudioComponent> = new Map<string, ParticipantAudioComponent>();
  previousScreenShareParticipantSocketId: string;
  
  @ViewChildren('participantvideo') participantVideoComponents: QueryList<ParticipantVideoComponent>;
  @ViewChildren('participantaudio') participantAudioComponents: QueryList<ParticipantAudioComponent>;
  @ViewChild('focusedparticipant') focusedParticipantComponent: ParticipantVideoComponent;
  @ViewChild('screensharevideo') screensharevideo: ElementRef;
  @ViewChild('meParticipantVideoComponent') meParticipantVideoComponent: ParticipantVideoComponent;
  @ViewChild('meParticipantAudioComponent') meParticipantAudioComponent: ParticipantAudioComponent;
  @ViewChild('meetingChat') meetingChat: MeetingChatComponent;
  @ViewChildren('participantcontainerdiv') participantcontainerdivs: QueryList<ElementRef>;
  @ViewChild('participantsdiv') participantsdiv: ElementRef;
  @ViewChild('selectMediaComponent') selectMediaComponent: SelectMediaComponent;
  @ViewChild('audioDiv') audioDiv: ElementRef;
  @ViewChild('meetingContainer') meetingContainer: ElementRef;
  @ViewChild('participantsContainerDiv') participantsContainerDiv: ElementRef;
  @ViewChild('participantsList') participantsList: ParticipantsListComponent;
  @ViewChild('audio') audio: ElementRef
  //closeUnusedVideoConsumersInterval;
  //closeUnusedVideoConsumersFunction;
  handleVisibleVideoConsumersInterval;
  handleVisibleVideoConsumersFunction;
  
  setorderinterval: any;

  chatOpenedClosedSubject: Subject<boolean> = new Subject<boolean>();

  focusedMode = true;
  focusedModeBeforeAutoswitch = this.focusedMode;
  landscapeEnteredAutomatically = false;
  wakeLock: any;
  keepScreenOnFunction: any;
  setVisibilityFunction: any;
  meetingVisible: boolean = false;
  
  screenSharePlayInterval: any;
  
  participantScreenShareAudio: HTMLAudioElement = new Audio();
  
  //labelNotifications: any[] = [];
  labelNotification: any = {};
  labelNotificationTimeout = 5 * 1000;
  labelNotificationVisibleTimeout: any;
  labelNotificationInvisibleTimeout: any;
  userJoinedAudio: HTMLAudioElement;
  networkLostAudio: HTMLAudioElement;
  lastUserLeftAudio: HTMLAudioElement;
  attendanceTimeRemaining :any;

  audioEnabledStateBeingChanged = false;
  videoEnabledStateBeingChanged = false;
  screenShareEnabledStateBeingChanged = false;

  networkLostAudioInterval = undefined;
  maximumIdleSeconds = (!environment.production ? 1 : 5) * 60;
  idleTimeout: any;
  //recordingProcessingProgress: number;
  //destroyed = false;
  
  //enableaudiofortest: boolean = true;
  //enablevideofortest: boolean = true;
  constructor(public meetingservice: MeetingService, 
              public meetingHandler: MeetingHandlerService, 
              private activatedroute: ActivatedRoute, 
              private router: Router, 
              public util: UtilService, 
              public settings: MeetingSettings, 
              private ref: ChangeDetectorRef, 
              private toastr: ToastrService, 
              private titleService: Title,
              private audioProcessingService: AudioProcessingService,
              private meetingElement: ElementRef,
              private localRecordingService: LocalRecordingService,
              private localRecordingEventsService: LocalRecordingEventsService,
              private iframeHandlerService: IframeHandlerService) { 
    
    this.recalculateParticipantPagesFunction = this.resized.bind(this);
    this.handleVisibleVideoConsumersFunction = this.handleVisibleVideoConsumers.bind(this);
    this.keepScreenOnFunction = this.keepScreenOn.bind(this);
    this.setVisibilityFunction = this.setVisibility.bind(this);
    this.screenSharePlayInterval = setInterval(this.screenSharePlayIntervalHandler.bind(this), 1000);
    //this.closeUnusedVideoConsumersFunction = this.closeUnusedVideoConsumers.bind(this);
    let opacity_i = 0;
    this.loadNotificationSounds();

    setInterval(() => {
      //here, we make the recording indicator blink every second
      opacity_i++;
      //there are two recording indicators, so we'll just use jquery to capture both of them
      $('.recording-indicator').css('opacity', opacity_i % 2 == 0 ? '1' : '0');
    }, 1000);

    if(this.mergeOutputAudioStreams){
      setInterval(this.playIntervalHandler.bind(this), 1000);
    }
    //console.log('creating meeting component');
  }

  get mergeOutputAudioStreams(){
    return this.audioProcessingService.mergeOutputAudioStreams;
  }

  async ngAfterViewInit() {
    if(this.mergeOutputAudioStreams){
      this.audio.nativeElement.srcObject = this.audioProcessingService.remoteMediaStreamDestination.stream;
    }

    this.meetingVisible = true;
    this.selectMediaComponent.inMeeting = true;
    
    this.resized();

    //if(this.util.mobile()){
    this.keepScreenOn();
    document.addEventListener('visibilitychange', this.keepScreenOnFunction);
    document.addEventListener('visibilitychange', this.setVisibilityFunction);
    //}
  }
  ngAfterContentInit(): void {
    //this.closeUnusedVideoConsumersInterval = setInterval(this.closeUnusedVideoConsumersFunction, 5000);//every 5 seconds, search for and close any invisible
    this.handleVisibleVideoConsumersInterval = setInterval(this.handleVisibleVideoConsumersFunction, 1000);
  }
  async ngOnDestroy() {
    if(this.recording){
      this.endRecordMeeting(false);
    }

    clearInterval(this.screenSharePlayInterval);
    // if(this.localstream){
    //   this.localstream.getTracks().forEach((track) => {
    //     track.stop();
    //   });
    // }
    // if(this.closeUnusedVideoConsumersInterval){
    //   clearInterval(this.closeUnusedVideoConsumersInterval)
    // }
    document.removeEventListener('visibilitychange', this.keepScreenOnFunction);
    document.removeEventListener('visibilitychange', this.setVisibilityFunction);
    if(this.wakeLock){
      
      this.wakeLock.release();
    }

    if(this.handleVisibleVideoConsumersInterval){
      clearInterval(this.handleVisibleVideoConsumersInterval);
    }

    if(this.setorderinterval){
      clearInterval(this.setorderinterval);
    }

    if(this.networkLostAudioInterval){
      clearInterval(this.networkLostAudioInterval);
    }

    this.meetingHandler.destroy();
    this.audioProcessingService.destroy();
    await this.localRecordingService.destroy();

    this.util.exitFullscreen();

    window.removeEventListener('resize', this.recalculateParticipantPagesFunction);

    this.util.exitLandscape();

    //this.destroyed = true;

    // this.recordingService.recordingEventSubject.unsubscribe());
    // this.recordingService.processingProgressSubject.unsubscribe();
  }

  
  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    //return false;
    //console.log(`visibility state: ${document.visibilityState}`);
    //if (document.visibilityState === 'hidden') {
      this.meetingservice.selfDisconnectAndDeassign(this.meetingHandler.domain, this.meetingHandler.jwt, this.meetingHandler.clientInstanceId);
      
      //calling this shouldn't be necessary because the server already does it. However, for insurance...
      this.meetingservice.deassignMeetingServer(this.meetingHandler.meetingid, this.meetingHandler.jwt, this.meetingHandler.clientInstanceId);
    //}
    
    // if(this.meetingHandler.usersIncremented){
    //   this.meetingservice.decrementUsers(this.meetingHandler.meetingid, this.meetingHandler.jwt).toPromise();//no need to await
  // }
  }

  async ngOnInit() {
    this.meetingHandler.audioPublishedSubject.subscribe(() => this.audioPublished());
    this.meetingHandler.audioStoppedSubject.subscribe(() => this.audioStopped());
    this.meetingHandler.videoPublishedSubject.subscribe(() => this.videoPublished());
    this.meetingHandler.videoStoppedSubject.subscribe(() => this.videoStopped());
    this.meetingHandler.participantRemovedSubject.subscribe(socketId => this.participantRemoved(socketId));
    this.meetingHandler.participantBeingRemovedSubject.subscribe(socketId => this.participantBeingRemoved(socketId));
    this.meetingHandler.participantProducerRemovedSubject.subscribe(params => this.participantProducerRemoved(params));
    this.meetingHandler.screenSharePublishedSubject.subscribe((producers) => {
      this.resized();

      if(this.meetingHandler.screenShareVideoTrack){
        this.screensharevideo.nativeElement.srcObject = new MediaStream([this.meetingHandler.screenShareVideoTrack]);
      }

      if(this.meetingHandler.screenShareAudioTrack){
        this.localRecordingService.addScreenShareAudio(this.meetingHandler.screenShareAudioTrack);
      }
      
      if(!this.util.mobile()){
        this.switchLayout(true, true);
      }
    });

    //set the order of those that had come in before the subscription below
    let i = 1;
    for(const [socketId, participant] of this.meetingHandler.participantsMap){
      if(i == this.meParticipantReservedOrder){//2 is reserved
        i++;
      }
      participant.order = i;
      i++;
      this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.participantAddedMessageId, data: {name: participant.name, host: participant.host, username: participant.username, photourl: participant.photourl}});
    }

    this.meetingHandler.participantAddedSubject.subscribe((participant: Participant) => {
      this.clearIdleTimeout();

      console.log('participant added: ', participant);
      if(this.meetingHandler.participantsMap.size < 20){
        this.addLabelNotification(`${participant.name} joined`);
      }
      if(this.meetingHandler.participantsMap.size == 1){
        this.userJoinedAudio.play();
      }
      this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.participantAddedMessageId, data: {name: participant.name, host: participant.host, username: participant.username, photourl: participant.photourl}});
      participant.order = this.meetingHandler.participantsMap.size + 1;
      if(participant.order == this.meParticipantReservedOrder){
        //order 2 is reserved for the meParticipant
        participant.order++;
      }
      this.setCentreDivWidth();
      
      setTimeout(() => {
        this.resized();
      }, 100);
    });

    this.meetingHandler.meetingEndedSubject.subscribe((endingSocketId) => {
      //debugger;
      if(endingSocketId != this.meetingHandler.meParticipant.socketid){
        this.navigateToMeetingEnded();
      }
    });
    this.meetingHandler.producerTypeUnavailableSubject.subscribe((producer) => {
      if (this.meetingHandler.screenShareVideoTrack && producer.track.id == this.meetingHandler.screenShareVideoTrack.id) {
        this.resized();  
        this.restoreFromAutoLayoutSwitch();
      }
    });
    this.meetingHandler.takeAttendanceSubject.subscribe(() => this.showAttendanceModal());
    this.meetingHandler.reconnectingSubject.subscribe(() => {
      // if(this.meetingHandler.screenShareAvailable){
      //   this.stopScreenShare();
      // }
      $('#reconnectingModal').modal('show');
      this.networkLostAudioInterval = setInterval(() => {
        this.networkLostAudio.play();
      }, 2000);
    });
    this.meetingHandler.reconnectedSubject.subscribe(() => {
      $('#reconnectingModal').modal('hide');
      clearInterval(this.networkLostAudioInterval);
    });
    // this.meetingHandler.toastrError.subscribe(message => {
    //   this.toastr.error(message);
    // });
    // this.meetingHandler.toastrWarning.subscribe(message => {
    //   this.toastr.warning(message);
    // });
    // this.meetingHandler.toastrSuccess.subscribe(message => {
    //   this.toastr.success(message);
    // });
    this.meetingHandler.notifySubject.subscribe((message) => {
      console.log('notification: ', message);
      const messageid = message.messageid;
      const data = message.data;
      
      switch(messageid){
        case NotificationMessages.microphonedisabledbyhost:
          this.toastr.warning('Your microphone has been disabled by the host');
          break;
        case NotificationMessages.notyetenabledtospeak:
          this.toastr.warning('The host has not yet enabled you to speak');
          break;
        case NotificationMessages.webcamdisabledbyhost:
          this.toastr.warning('Your webcam has been disabled by the host');
          break;
        case NotificationMessages.enabledtospeak:
          this.toastr.success('You have been anabled to speak');
          break;
        case NotificationMessages.enteredroom:
          this.toastr.success(`You are now in room '${data.room}'`);
          break;
        case NotificationMessages.brokenout:
          this.toastr.success('Broken out.');
          break;
        case NotificationMessages.errorproducing:
          this.toastr.error(data);
          break;
        case NotificationMessages.errorGettingVideoStream:
          this.toastr.error('Error getting video stream. Please ensure you have enabled camera access permissions');
          break;
        case NotificationMessages.errorGettingAudioStream:
          this.toastr.error('Error getting audio stream. Please ensure you have enabled microphone access permissions');
          break;
        case NotificationMessages.maxSpeakingParticipantsReached:
          this.toastr.error(`Maximum of ${this.meetingHandler.maxSpeakingParticipants} speaking participants reached`);
          break;
        case NotificationMessages.errorSharingScreen:
          this.toastr.error(`Error sharing screen`);
        default:
          this.toastr.warning(`Unknown message id ${messageid}`);
      }
    });
    this.meetingHandler.participantHandRaisedSubject.subscribe(participant => {
      if((this.focusedMode || !this.allParticipantsVisible()) && participant.order != 1){
        const previousOrder = participant.order;
        //when someone raises their hand, we'll set them to the second participant...that way, they won't be focused
        this.shiftParticipantUp(participant, 3);
      }
    });
    this.meetingHandler.connectedSubject.subscribe(async (first: boolean) => {
      $('#reconnectingModal').modal('hide');
      clearInterval(this.networkLostAudioInterval);
      if(!this.meetingHandler.cloudRecorder && (this.meetingHandler.meetinginfo.mode == this.util.meetingmode || this.meetingHandler.meParticipant.host)){
        await this.publishMedia();
      }
      // if(!this.meetingHandler.recorder){
        
      // }
    });
    this.meetingHandler.handDroppedSubject.subscribe((message) => {
        const socketId = message.socketId;
        const remoteId = message.remoteId;//the user who dropped the hand
        const unlocked = message.unlocked;
        const timeout = message.timeout;

      if(socketId == this.meetingHandler.clientInstanceId){
        this.meetingHandler.handraised = false;
        if(timeout || (socketId != remoteId && !unlocked)){
          //if the user didn't drop his own hand, and it wasn't dropped as a result of unlocking then notify him
          this.toastr.warning('Hand Dropped');
        }
        else if(unlocked && this.meetingHandler.meParticipant.unlocked){
          //if the user had raised his hand and was then unlocked by the host, he should immediately start sending audio
          this.settings.enableMicrophone = true;
          this.publishAudio(false);
        }
      }
      else{
        if(this.meetingHandler.participantsMap.has(socketId)){
          const participant = this.meetingHandler.participantsMap.get(socketId);
          participant.handRaised = false;
        }
      }
    });
    // this.meetingHandler.remoteProducerPausedSubject.subscribe((message) => {
      
    // });
    // this.meetingHandler.remoteProducerResumedSubject.subscribe((message) => {
      
    // });
    this.meetingHandler.videoConsumerRemovedSubject.subscribe((socketId) => {
      const participantVideoComponent = this.getParticipantVideoComponent(socketId);
      if(participantVideoComponent){
        participantVideoComponent.setStream();
      }
    });
    this.meetingHandler.audioConsumerRemovedSubject.subscribe((socketId) => {
      const participantAudioComponent = this.getParticipantAudioComponent(socketId);
      if(participantAudioComponent){
        participantAudioComponent.setStream();
      }
    });
    this.meetingHandler.screenShareVideoConsumerRemovedSubject.subscribe((socketid) => {
      if(!this.meetingHandler.screenShareVideoParticipantProducerId){
        this.restoreFromAutoLayoutSwitch();
      }
    });
    this.meetingHandler.screenShareAudioConsumerRemovedSubject.subscribe((socketid) => {

    });
    this.audioProcessingService.audioLevelReportedSubject.subscribe((data) => {
      //console.log(data);
      const {participantSocketId, volumeLevel} = data;

      if(participantSocketId){
        const participant = this.meetingHandler.participantsMap.get(participantSocketId);

        if(participant){
          //i'm not using angular binding because of performance
          //also, calling detectChanges() so frequently will be an issue
          this.getParticipantVideoComponent(participant.socketid)?.setVolumeLevel(volumeLevel);
        }
      }
      else{
        if(this.meParticipantVideoComponent){
          this.meParticipantVideoComponent.setVolumeLevel(volumeLevel);
        }
      }
      //shiftParticipantsOrder
    });
    this.audioProcessingService.loudestParticipantReportedSubject.subscribe((data) => {
      const {participantSocketId, volumeLevel} = data;

      //console.log('loudest participant: ', data);

      //it could be the volume level that is being checked from select-media component. We don't want to include that here because that's for the current user
      if(participantSocketId && participantSocketId != MeetingHandlerService.previewAudioLevelId){
        const participant = this.meetingHandler.participantsMap.get(participantSocketId);

        if(participant){
          const focusedMode = this.focusedMode;
          if(!focusedMode){
            const videoComponent = this.getParticipantVideoComponent(participant.socketid);
            if(videoComponent && !videoComponent.scrolledIntoView(true)){
              this.shiftParticipantUp(participant, 1);
            }
            else{
              //console.log(`loudest participant ${participant.socketid} is already scrolled into view`);
            }
          }
          else{
            //console.log('this: ', this);
            //in focused mode, we always want the speaker to be in pole position. Later on, we may have to make sure this movement doesn't occur more than x number of seconds
            this.shiftParticipantUp(participant, 1);
          }
        }
      }
      //if meeting mode, loudest should be number 1
    })

    this.meetingHandler.screenShareVideoProducerAddedSubject.subscribe(async (data: any) => {
      await this.handleScreenShareVideoProducerAdded();
    });

    this.meetingHandler.screenShareAudioProducerAddedSubject.subscribe(async (data: any) => {
      await this.handleScreenShareAudioProducerAdded();
    })

    this.meetingHandler.remoteAudioProducerAddedSubject.subscribe(async (data: any) => {
      const {socketid, producerid} = data;
      const participantAudioComponent = this.getParticipantAudioComponent(socketid);
      if(participantAudioComponent){
        await participantAudioComponent.setStream();
      }
    });
    
    this.meetingHandler.remoteVideoProducerAddedSubject.subscribe(async (data) => {
      const {socketid, producerid} = data;
    });

    this.meetingHandler.leaveMeetingSubject.subscribe(async () => {
      await this.leaveMeeting();
    });


    //Don't forget to unsubscribe these in ngOnDestroy

    //debugger;
    //this.resized();
    window.addEventListener('resize', this.recalculateParticipantPagesFunction);

    this.meetingHandler.meetingid = this.activatedroute.snapshot.params.meetingid;
    this.meetingHandler.jwt = this.activatedroute.snapshot.queryParams.auth;
    this.meetingHandler.cloudRecorder = this.activatedroute.snapshot.queryParams.recorder;
    this.meetingHandler.test = this.activatedroute.snapshot.queryParams.test == 'true';

    if(!this.meetingHandler.isMediasoupControlSocketConnected() && !this.meetingHandler.cloudRecorder){

      this.navigateToJoin();
      return;
    }

    this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.userJoinedMessageId});
    //this.enableaudiofortest = this.activatedroute.snapshot.queryParams.enableaudio == 'true';
    //this.enablevideofortest = this.activatedroute.snapshot.queryParams.enablevideo == 'true';
    
    if(this.meetingHandler.cloudRecorder){
      await this.meetingHandler.init();

      console.log('calling connect to meeting until successful from meeting component ngOnInit()');
      await this.meetingHandler.connectToMeetingUntilSuccessful();

      console.log('__ready_to_record__');

      
    }

    if(this.meetingHandler.participantsMap.size == 0){
      this.setIdleTimeout();
    }

    this.goToDefaultMode();

    if(this.meetingHandler.screenShareVideoParticipantProducerId && !this.meetingHandler.screenShareVideoConsumer){
      this.handleScreenShareVideoProducerAdded();
    }

    if(this.meetingHandler.screenShareAudioParticipantProducerId && !this.meetingHandler.screenShareAudioConsumer){
      this.handleScreenShareAudioProducerAdded();
    }

    if(!this.meetingHandler.cloudRecorder && (this.meetingHandler.meetinginfo.mode == this.util.meetingmode || this.meetingHandler.meParticipant.host)){
      await this.publishMedia();
    }
    //debugger;

    // if(this.recorder){
    //   this.meetinginfo.domains.splice(1);
    // }

    if(this.meetingHandler.cloudRecorder){
      this.titleService.setTitle(`VCircle`);
    }
    else{
      this.titleService.setTitle(`${this.meetingHandler.meetinginfo.title} - VCircle`);
    }

    if(this.meetingHandler.meetinginfo.mode == this.util.meetingmode){
      this.maxDisplayedParticipants = 21;
    }
    else{ //if(this.meetinginfo.mode == this.util.focusmode){
      this.maxDisplayedParticipants = 21;
    }

    if(this.meetingHandler.meetinginfo.ended){
      this.navigateToMeetingEnded();
      return;
    }

    if(this.localRecordingService.startRecordingImmediately){
      $('#recordMeetingModal').modal('show');
    }

    // if(this.meetingHandler.cloudRecorder){
    //   await this.startRecording();
    // }

    //debugger;

    

    // if(!this.setorderinterval){
    //   this.setorderinterval = setInterval(() => {
    //     this.meetingHandler.setParticipantsOrder(this.allParticipantsVisible());
    //   }, 2000);
    // }

    // if(this.recorder){
    //   this.meetinginfo.mode = "focusmode";
    // }

    

    

    //this.participantsMap = new Map<string, Participant>();
    //this.participantsArray = [];

    

    //await this.addSocketIO();
    

    // if(this.meetinginfo.mode == this.util.meetingmode){
    //   const setenabledconsumers = async () => {
    //     if(!this.leaving){
    //       try{
    //         await this.enableLoudestAudioConsumers();//right now, based on the algorithm used in these functions, loudest must be enabled before quitest are disabled
    //         await this.disableQuietestAudioProducers();
    //       }
    //       finally{
    //         setTimeout(setenabledconsumers, 1000);
    //       }
    //     }
    //   };
    //   setenabledconsumers();
    // }
  }

  private endCloudRecording(reason: string){
    console.log(`ending cloud recording. reason: '${reason}'`);
    console.log('__stop_recording__');
  }

  private setIdleTimeout() {
    this.idleTimeout = setTimeout(() => {
      if (this.meetingHandler.cloudRecorder) {
        this.endCloudRecording('idle timeout');
      }
      else {
        //showNoUsersPopup()//to be used to confirm if users are still there
      }
    }, this.maximumIdleSeconds * 1000);
  }

  private clearIdleTimeout(){
    if(this.idleTimeout){
      clearTimeout(this.idleTimeout);
    }
  }

  private async handleScreenShareVideoProducerAdded() {
    //we need to make sure the user is scrolled to the top to ensure they see the screen share
    if(this.meetingContainer){
      this.meetingContainer.nativeElement.scrollTo(0, 0);
    }
    if(this.participantsContainerDiv){
      this.participantsContainerDiv.nativeElement.scrollTo(0, 0);
    }
    if (this.meetingHandler.screenShareVideoConsumer && this.previousScreenShareParticipantSocketId) {
      await this.meetingHandler.closeConsumer(this.previousScreenShareParticipantSocketId, this.meetingHandler.screenShareVideoConsumer);
      this.previousScreenShareParticipantSocketId = this.meetingHandler.screenShareParticipantSocketId;
    }

    const participant = this.meetingHandler.participantsMap.get(this.meetingHandler.screenShareParticipantSocketId);

    this.meetingHandler.screenShareVideoConsumer = (await this.meetingHandler.createConsumer(this.meetingHandler.screenShareParticipantSocketId, this.meetingHandler.screenShareVideoParticipantProducerId, 'video')).consumer;
    this.meetingHandler.setPreferredConsumerSpatialLayer(this.meetingHandler.screenShareParticipantSocketId, this.meetingHandler.screenShareVideoConsumer.id, 1);
    
    if (!this.util.mobile()) {
      this.switchLayout(true, true);
    }
    this.meetingHandler.resumeConsumer(participant.socketid, this.meetingHandler.screenShareVideoConsumer, false);

    this.resized();
    this.screensharevideo.nativeElement.srcObject = new MediaStream([this.meetingHandler.screenShareVideoConsumer.track]);
  }

  private async handleScreenShareAudioProducerAdded() {
    if (this.meetingHandler.screenShareVideoConsumer && this.previousScreenShareParticipantSocketId) {
      await this.meetingHandler.closeConsumer(this.previousScreenShareParticipantSocketId, this.meetingHandler.screenShareVideoConsumer);
      this.previousScreenShareParticipantSocketId = this.meetingHandler.screenShareParticipantSocketId;
    }

    this.meetingHandler.screenShareAudioConsumer = (await this.meetingHandler.createConsumer(this.meetingHandler.screenShareParticipantSocketId, this.meetingHandler.screenShareAudioParticipantProducerId, 'audio', false)).consumer;

    const screenShareAudioStream = new MediaStream([this.meetingHandler.screenShareAudioConsumer.track]);

    this.participantScreenShareAudio.srcObject = screenShareAudioStream;

    this.audioProcessingService.publishAudioStreamToBrowser(this.meetingHandler.screenShareParticipantSocketId, screenShareAudioStream, true);
  }

  private navigateToMeetingEnded() {
    this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.meetingEndedMessageId});
    this.router.navigate([`/ended/${this.meetingHandler.meetingid}`], { queryParams: { auth: this.meetingHandler.jwt } });
    setTimeout(() => {
      this.endCloudRecording('meeting ended');
    }, 1000);
  }

  private navigateToJoin(){
    this.router.navigate([`/join/${this.meetingHandler.meetingid}`], { queryParams: {auth: this.meetingHandler.jwt, test: this.meetingHandler.test} });
  }

  private navigateToUserLeft(){
    this.meetingservice.deassignMeetingServer(this.meetingHandler.meetingid, this.meetingHandler.jwt, this.meetingHandler.clientInstanceId);
    this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.userLeftmessageId});
    this.router.navigate([`/end/${this.meetingHandler.meetingid}`], { queryParams: { auth: this.meetingHandler.jwt } });
  }

  // async connectToMeeting(){
  //   //TODO: redo this to implement the confirm and retry here
  //   const ret = await this.meetingHandler.connectToMeeting();

  //   if(!ret){
  //     const retry = await new Promise((accept, reject) => {
  //       this.confirm(() => {
  //         accept(true);
  //       }, 
  //       () => {
  //         accept(false);
  //         this.navigateToUserLeft();
  //       },
  //       'Unable to connect',
  //       'Retry?');
  //     });

  //     if(retry){
  //       setTimeout(this.connectToMeeting.bind(this), 100);
  //     }

  //     return;
  //   }

    
  // }
  

  async audioPublished(){
    //this.meParticipantAudioComponent.audio.nativeElement.muted = true;
    const mediaStream: MediaStream = new MediaStream([this.meetingHandler.localAudioTrack]);
    //this.meParticipantAudioComponent.audio.nativeElement.srcObject = mediaStream;

    this.audioProcessingService.monitorAudioLevel('', mediaStream);

    this.localRecordingService.addMicrophoneAudio(this.meetingHandler.localAudioTrack);
  }

  async audioStopped(){
    this.localRecordingService.removeMicrophoneAudio();
  }

  async videoPublished(){
    this.meParticipantVideoComponent.video.nativeElement.muted = true;
    this.meParticipantVideoComponent.video.nativeElement.srcObject = new MediaStream([this.meetingHandler.localVideoTrack]);
    this.meParticipantVideoComponent.visible = true;
  }

  async videoStopped(){

  }

  // identifyParticipant(index, item: any){
  //   return item.key;
  // }

  identifyParticipant(index, item: Participant){
    return item.socketid;
  }

  identifyParticipantInMap(index, item: any){
    return item.value;
  }

  async participantRemoved(socketId){
    //await this.meetingHandlerService.removeParticipant(socketId);

    if(this.meetingHandler.meParticipant && socketId != this.meetingHandler.meParticipant.socketid){
      if(this.meetingHandler.participantsMap.size == 0){
        this.endCloudRecording('participant removed');//once the last participant is removed, no point recording any longer
      }
      else{
        this.setIdleTimeout();
      }
    }
    
    this.setCentreDivWidth();
  }

  participantBeingRemoved(socketId){
    const participant = this.meetingHandler.participantsMap.get(socketId);
    if(this.meetingHandler.participantsMap.size < 20){
      this.addLabelNotification(`${participant.name} left`);
      if(this.meetingHandler.participantsMap.size==1){
        this.lastUserLeftAudio.play();
      }
    }
    this.iframeHandlerService.postMessageToParent({messageId: IframeHandlerService.participantRemovedMessageId, data: {name: participant.name, host: participant.host, username: participant.username, photourl: participant.photourl}});
    for (let [key, value] of this.meetingHandler.participantsMap) {
      //move all participants below this participant up
      if (value.order >= participant.order) {
        value.order--;
      }
    }
  }

  async participantProducerRemoved(params) {
    const {socketId, producerId, kind} = params;
    //await this.meetingHandlerService.removeParticipantProducer(socketId, producerId, kind);
    if(this.meetingHandler.screenShareVideoParticipantProducerId == producerId && this.meetingHandler.screenShareVideoConsumer){
      this.resized();
      this.screensharevideo.nativeElement.srcObject = null;
      this.restoreFromAutoLayoutSwitch();
    }
  }

  playIntervalHandler(){
    //on mobile, audio/video may have been stopped because the user left the application and played audio from a different application
    if(this.audioProcessingService.mergeOutputAudioStreams){
      if(this.audio?.nativeElement.srcObject && this.audio.nativeElement.paused && !this.audio.nativeElement.ended){
        this.audio.nativeElement.play();
      }
    }
  }

  

  // async disableQuietestAudioProducers() {
  //   //what i was trying to do here won't work because the 'loudestProducers' event doesn't give us ALL producers
  //   // for(let i = this.loudestProducers.length - 1; i >= 0; i--){
  //   //   const producerId = this.loudestProducers[i].producerId;
  //   //   const volume = this.loudestProducers[i].volume;
  //   // }

    
  //     //pause all the consumers that are were not loud enough to make the cut in this pass
  //     //we can't iterate over this.enabledConsumers because the new consumers won't be added there
  //     const consumersinfo: any[] = [];
  //     const promises = [];
  //     for (const [producerId, consumer] of this.audioConsumersMap) {
  //       //const consumerId = this.audioConsumersMap.get(producerId)
  //       const socketId = this.producerIdSocketIdMap.get(producerId);
  //       const participant = this.participantsMap.get(socketId);
  //       if (!this.loudConsumers.has(producerId) && !consumer.paused && participant && !participant.host) 
  //       {
  //         //consumer.pause();
  //         promises.push(this.pauseConsumer(socketId, consumer, true));
  //         //consumersinfo.push({socketId: socketId, consumer: consumer});
  //         if (this.enabledConsumers.has(producerId)) {
  //           this.enabledConsumers.delete(producerId);
  //         }
  //       }
  //     }
  //     await Promise.all(promises);
  //     //await this.pauseConsumers(consumersinfo, false);
  // }

  // async enableLoudestAudioConsumers() {
  //   const loudConsumers = new Set<string>();

  //   //enable the loudest 25 audio producers
  //   //if(){
  //   //enable the loudest 25 audio producers
  //   let max = this.maxPlayingAudioParticipants;
  //   /*if(this.maxPlayingAudioParticipants == 0){
  //     max = 1;
  //   }*/
  //   const consumersinfo: any[] = [];
  //   const promises = [];
  //   for (let i = 0; i < max && i < this.loudestProducers.length; i++) {
  //     const producerId = this.loudestProducers[i].producerId;
  //     const socketId = this.producerIdSocketIdMap.get(producerId);
  //     const volume = this.loudestProducers[i].volume;

  //     //if(this.producerIdSocketIdMap.has(producerId)){
  //     //const socketId = this.producerIdSocketIdMap.get(producerId);
  //     //if(this.participantsMap.has(socketId)){
  //     //const participant = this.participantsMap.get(socketId);
  //     loudConsumers.add(producerId);
  //     if (this.audioConsumersMap.has(producerId)) {
  //       const consumer = this.audioConsumersMap.get(producerId);
  //       if (consumer.paused) {
  //          //consumer.resume();//rather than pausing on the server, we should just pause on the client...after experimenting with pausing on the server, it doesn't seem to be beneficial...what may be beneficial is pausing the producer on silence after x seconds of silence
  //          promises.push(this.resumeConsumer(socketId, consumer, true));
  //       }
  //       //consumersinfo.push({socketId: socketId, consumer: consumer});
  //     }

  //     if (!this.enabledConsumers.has(producerId)) {
  //       this.enabledConsumers.add(producerId);
  //     }

      
  //     /*if(participant.participantAudioComponent && participant.participantAudioComponent.audioConsumer && participant.participantAudioComponent.audioConsumer.paused){
  //       participant.participantAudioComponent.audioConsumer.resume();
  //     }*/
  //     //}
  //     //}
  //   }
  //   await Promise.all(promises);

  //   //await this.resumeConsumers(consumersinfo, true);

  //   this.loudConsumers = loudConsumers;

    
  //   //return loudConsumers;
  // }

  //TODO: we may need to handle more than just the loudest participant. maybe put all those who are loud in front...
  //or put loudest in front, followed by raised hands, then the rest of the loud participants...then the rest
  showMoreParticipants(){
    this.maxDisplayedParticipants += this.participantsPerShowMore;
  }

  showAttendanceModal(){
    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let width = parseInt($('#attendanceModal').css('width'), 10);
    let height = parseInt($('#attendanceModal').css('height'), 10);

    let top = h *  Math.random();
    let left = w * Math.random();
    if(top + height > h){
      top = h - height;
    }
    if(left + width > w){
      left = w - width;
    }
    $('#attendanceModal').modal('show');
    $('#attendanceModal').css({'top':`${top}px`, 'left':`${left}px`});
    let timeOut = 30;
    this.attendanceTimeRemaining = timer(0, 1000).pipe(
      map((n) => (timeOut - n)),
      takeWhile((n) => n >= 0)
    );
    setTimeout(()=>{
      this.closeAttendanceModal();
    }, timeOut*1000);
  }

  attendanceClicked(){
    this.meetingHandler.markAttendanceTaken();
    this.closeAttendanceModal();
  }

  closeAttendanceModal(){
    $('#attendanceModal').modal('hide');
  }

  // async closeProducerTransport() {
  //   try {
  //     await this.sendMediasoupControlRequest('closeProducerTransport', { socketId: this.socket.id });
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.mediasoupProducerTransport.close();
  //   this.mediasoupProducerTransport = null;
  // }

  

  resized(){
    this.setCentreDivWidth();
    if(this.chatopen){
      this.meetingChat.scrollToBottomOfChat();
    }
    // this.setPages();
    // this.goToPage(this.participantsPageNo);
  }

  // setPages() {
  //   //this.pages = [];
  //   //this.settings.participantsperpage 
  //   if(this.participantcontainerdivs.length > 0){
  //     this.settings.participantsperpage = Math.floor(($(this.participantsdiv.nativeElement).innerHeight() - 80) / $(this.participantcontainerdivs.first.nativeElement).outerHeight());
  //   }
  //   else{
  //     this.settings.participantsperpage = 1;
  //   }
  //   this.participantsNumPages = Math.ceil((this.participantsMap.size - (this.focusDivUnavailable() ? 0 : 1)) / this.settings.participantsperpage);
  //   // for (let i = 1; i <= numPages; i++) {
  //   //   this.pages.push(i);
  //   // }
  // }

  focusDivUnavailable(){
    return this.meetingHandler.participantScreenShareAvailable || this.meetingHandler.screenShareAvailable;
  }

  // private setParticipantStartIndex(pageNo: number) {
  //   this.participantStartIndex = this.settings.participantsperpage * (pageNo - 1) + 1;
  //   if (this.focusDivUnavailable()) {
  //     this.participantStartIndex -= 1;
  //   }
  // }
  
  // goToPage(pageNo: number) {
  //   //pause the consumers i was viewing before
  //   //for (let i = this.procteeStartIndex; i < this.procteeEndIndex; i++) {
  //   //  if (this.proctees.length > i) {
  //   //    const proctee = this.proctees[i];
  //   //    this.pauseConsumers(proctee);
  //   //  }
  //   //}
  //   if (pageNo != this.participantsPageNo) {
  //     this.localMediasoupProducers.forEach((producer) => {
  //       if (producer.appData.recepientSocketId) {//close all private producers
  //         this.closeProducer(producer);
  //       }
  //     });
  //   }
  
  //   this.participantsPageNo = pageNo;
  //   // this.setParticipantStartIndex(pageNo);
  //   // this.participantEndIndex = this.participantStartIndex + this.settings.participantsperpage;
  
  //   // const participantprocteesarray: Participant[] = [...this.participantsMap.values()].slice(this.participantStartIndex, this.participantEndIndex);
  //   // const tmppageproctees = new Map<string, Participant>();
  //   // for (let i = 0; i < participantprocteesarray.length; i++) {
  //   //   tmppageproctees.set(participantprocteesarray[i].socketid, participantprocteesarray[i]);
  //   // }
  //   //this.pageparticipants = [...tmppageproctees.values()];

  //   // const pageparticipants = this.getPageParticipants();
  
  //   // if(pageparticipants.length == 0 && pageNo > 1){
  //   //   this.goToPage(pageNo - 1);//if there are no participants on this page, go to the previous page
  //   // }

  //   //resume the consumers we are viewing now
  //   //for (let i = this.procteeStartIndex; i < this.procteeEndIndex; i++) {
  //   //  if (this.proctees.length > i) {
  //   //    const proctee = this.proctees[i];
  //   //    this.resumeConsumers(proctee);
  //   //  }
  //   //}
  //   if (this.pageChangeTimeout) {
  //     clearTimeout(this.pageChangeTimeout);
  //   }
  //   if (this.settings.autoswitchpage) {
  //     this.pageChangeTimeout = setTimeout(() => {
  //       if (pageNo >= this.participantsNumPages) {
  //         pageNo = 0;
  //       }
  //       console.log(`going to page ${pageNo + 1}`);
  //       this.goToPage(pageNo + 1);
  //       this.ref.detectChanges();
  //     }, this.settings.secondsperpage * 1000);
  //   }
  // }

  // getVisibleParticipants(){
  //   const participantsinview = [this.settings.focusedParticipant, ...this.getPageParticipants()];
  //   return participantsinview;
  // }

  // getPageParticipants(){
  //   //const pageparticipants = this.participantsArray.slice(this.participantStartIndex, this.participantEndIndex);
  //   let pageparticipants = new OrderParticipantsPipe(this.settings).transform(this.participantsMap, true);
  //   pageparticipants = new SlicePipe().transform(pageparticipants, this.participantStartIndex, this.participantEndIndex);
  //   //pageparticipants.slice(this.participantStartIndex, this.participantEndIndex);
  //   return pageparticipants;
  // }

  // prevParticipantsPage(){
  //   if(this.participantsPageNo > 1){
  //     this.goToPage(this.participantsPageNo - 1);
  //   }
  // }

  // nextParticipantsPage(){
  //   if(this.participantsPageNo < this.participantsNumPages){
  //     this.goToPage(this.participantsPageNo + 1);
  //   }
  // }

  getParticipantVideoComponent(socketId): ParticipantVideoComponent {
    if(this.meetingHandler.participantsMap.has(socketId)){
      const participant = this.meetingHandler.participantsMap.get(socketId);
      if(participant && this.participantVideoComponentsMap.has(socketId)){// participant.participantVideoComponent){
        //return participant.participantVideoComponent;
        return this.participantVideoComponentsMap.get(socketId);
      }
    }
    return null;
  }

  getParticipantAudioComponent(socketId): ParticipantAudioComponent{
    if(this.meetingHandler.participantsMap.has(socketId)){
      const participant = this.meetingHandler.participantsMap.get(socketId);
      if(participant && this.participantAudioComponentsMap.has(socketId)){// participant.participantAudioComponent){
        //return participant.participantAudioComponent;
        return this.participantAudioComponentsMap.get(socketId);
      }
    }
    return null;
  }

  // resumeProducerLocally(producer: any){
  //   producer.resume();
  // }

  async participantAudioToggled(participantvideocomponent: ParticipantVideoComponent){
    console.log('audio toggled for ', participantvideocomponent.participant);
    if(participantvideocomponent.participant.audioAvailable){
      await this.meetingHandler.closeParticipantProducer(participantvideocomponent.participant.socketid, participantvideocomponent.participant.audioProducerId, 'audio');
      //participantvideocomponent.audioEnabled = false;
    }
    // else{
    //   await this.resumeParticipantProducer(participantvideocomponent.participant.socketid, participantvideocomponent.participant.audioProducerId, 'audio')
    // }
  }

  async participantVideoToggled(participantvideocomponent: ParticipantVideoComponent){
    console.log('video toggled', participantvideocomponent.videoConsumer);
    if(participantvideocomponent.participant.videoAvailable){
      await this.meetingHandler.closeParticipantProducer(participantvideocomponent.participant.socketid, participantvideocomponent.participant.videoProducerId, 'video');
      //participantvideocomponent.videoEnabled = false;
    }
    // else{
    //   await this.resumeParticipantProducer(participantvideocomponent.participant.socketid, participantvideocomponent.participant.videoProducerId, 'video');
    // }
  }

  async participantScreenShareToggled(){
    //console.log('screen share toggled', this.screenShareConsumer);
    if(this.meetingHandler.participantScreenShareAvailable){
      await this.meetingHandler.closeParticipantProducer(this.meetingHandler.screenShareParticipantSocketId, this.meetingHandler.screenShareVideoParticipantProducerId, 'video');
      //participantvideocomponent.videoEnabled = false;
    }
  }

  async participantLockToggled(participant: Participant){
    const res: any = await this.meetingHandler.setParticipantLock(participant);
    if(res.done){
      const previousOrder = participant.order;
      if(participant.order > 3){
        this.shiftParticipantUp(participant, 3);
      }
      //participant.unlocked = unlockstate;
      
    }
    else{
      this.toastr.error(res.message);
    }
  }

  async disconnectUserInitiated(participant: Participant){
    this.confirm(() => {
      this.meetingHandler.disconnectUser(participant.socketid);
    },
    () => {

    }, 
    'Disconnect User', 
    `Are you sure you want to disconnect ${participant.name}`);
  }

  async publishMedia(){
    const publishPromises = [this.publishAudio(true), this.publishVideo(true)];
    if(this.meetingHandler.screenShareVideoTrack){
      publishPromises.push(this.publishScreenShare(true));
    }
    await Promise.allSettled(publishPromises)
  }

  async publishAudio(auto: boolean){
    try{
      this.audioEnabledStateBeingChanged = true;
      await this.meetingHandler.publishAudio(auto);
    }
    finally{
      this.audioEnabledStateBeingChanged = false;
    }
  }

  async stopAudio(){
    try{
      this.audioEnabledStateBeingChanged = true;
      await this.meetingHandler.stopAudio();
    }
    finally{
      this.audioEnabledStateBeingChanged = false;
    }
  }

  async toggleUserMicrophone(){
    if(this.meetingHandler.audioAvailable){
      this.settings.enableMicrophone = false;
      await this.stopAudio();
    }
    else{
      this.settings.enableMicrophone = true;
      await this.publishAudio(false);
    }    
    //this.closeProducer()
  }

  async publishVideo(auto: boolean){
    try{
      this.videoEnabledStateBeingChanged = true;
      await this.meetingHandler.publishVideo(auto);
    }
    finally{
      this.videoEnabledStateBeingChanged = false;
    }
  }

  async stopVideo(){
    try{
      this.videoEnabledStateBeingChanged = true;
      await this.meetingHandler.stopVideo();
    }
    finally{
      this.videoEnabledStateBeingChanged = false;
    }
  }

  async toggleUserCamera(){
    if(this.meetingHandler.videoAvailable){
      this.settings.enableCamera = false;
      await this.stopVideo();
    }
    else{
      this.settings.enableCamera = true;
      await this.publishVideo(false);
    }
  }

  async publishScreenShare(republish = false){
    try{
      this.screenShareEnabledStateBeingChanged = true;
      await this.meetingHandler.publishScreenShare(republish);
    }
    finally{
      this.screenShareEnabledStateBeingChanged = false;
    }
  }

  async stopScreenShare(){
    try{
      this.screenShareEnabledStateBeingChanged = true;
      await this.meetingHandler.stopScreenShare();
    }
    finally{
      this.screenShareEnabledStateBeingChanged = false;
    }
  }

  async toggleUserScreenShare(){
    if(this.meetingHandler.screenShareAvailable){
      await this.stopScreenShare();
      this.screensharevideo.nativeElement.srcObject = null;
    }
    else{
      await this.publishScreenShare();
    }
  }

  screenSharePlayIntervalHandler(){
    //on mobile, audio/video may have been stopped because the user left the application and played audio from a different application
    if(this.screensharevideo?.nativeElement.srcObject && this.screensharevideo.nativeElement.paused && !this.screensharevideo.nativeElement.ended){
      this.screensharevideo.nativeElement.play();
    }
  }

  async endMeeting(){
    if(this.meetingHandler.localuserinfo.host){
      await this.meetingHandler.sendMediasoupControlRequest('endMeeting', {});
      //debugger;
      $('#endMeetingModal').modal('hide');
      await this.leaveMeeting(true);
      if(!this.recording){
        //$('.modal-backdrop.show').remove();
      }
      
    }
  }

  setVisibility(event: any){
    this.meetingVisible = document.visibilityState === "visible";
  }
  
  

  

  focused(_focused: boolean){
    console.log('focused', _focused);
  }

  async leaveMeeting(ended: boolean = false){
    await this.meetingHandler.leaveMeeting();
    $('#leaveModal').modal('hide');
    $('#endMeetingModal').modal('hide');
    if(this.recording){
      $('#recordingModal').modal('show');
      this.endRecordMeeting(true);
    }
    if(ended){
      this.navigateToMeetingEnded();
    }
    else{
      this.navigateToUserLeft();
    }
    
  }


  async showMediaSettings(){
    //we're doing this to make sure we get an updated list of devices
    await this.selectMediaComponent.populateVideoAndAudioSelects(false);
    
    this.selectMediaComponent.settingsVideo.videoEnabled = true;
    this.selectMediaComponent.settingsVideo.audioEnabled = true;
    this.selectMediaComponent.selectedCamera = this.meetingHandler.selectedCamera ?? this.util.defaultCamera;
    this.selectMediaComponent.selectedMicrophone = this.meetingHandler.selectedMicrophone ?? this.util.defaultMicrophone;

    await this.selectMediaComponent.showPreviews();
    //this.router.navigate([`/meeting/${this.meetingHandler.meetingid}/settings`], {skipLocationChange: true, queryParams: { x: this.settingsSNo }});
    
    $('#settingsModal').modal('show');
  }

  showParticipantsModal(){
    this.participantsList.detectChanges();
    $('#participantsModal').modal('show');
  }

  async changeSettings(){
    try{
      const promises: Promise<any>[] = [];
      //we are doing all these awaits so that we can tell the user if there's a problem
      if(this.selectMediaComponent.selectedMicrophone != this.meetingHandler.selectedMicrophone){
        this.settings.selectedMicrophone = this.selectMediaComponent.selectedMicrophone;

        if(this.settings.enableMicrophone){
          promises.push(this.changeMicrophone().catch((error) => {
            console.error(error);
            this.toastr.error('Unexpected error while publishing audio');
          }));    
        }
        // promises.push(this.changeMicrophone().catch((error) => {
        //   console.error(error);
        //   this.toastr.error('Unexpected error while changing microphone');
        // }));
      }
      if(this.selectMediaComponent.selectedCamera != this.meetingHandler.selectedCamera){
        this.settings.selectedCamera = this.selectMediaComponent.selectedCamera;

        if(this.settings.enableCamera){
          promises.push(this.changeCamera().catch((error) => {
            console.error(error);
            this.toastr.error('Unexpected error while publishing video');
          }));
        }
        // promises.push(this.changeCamera().catch((error) => {
        //   console.error(error);
        //   this.toastr.error('Unexpected error while changing camera');
        // }));
      }

      await Promise.all(promises);

      this.hideSettingsModal();
    }
    catch(error){
      console.error(error);
      this.toastr.error("Unexpected error while changing settings");
    }
  }

  hideSettingsModal(){
    this.selectMediaComponent.stopVideoStream();
    this.selectMediaComponent.stopAudioStream();
    $('#settingsModal').modal('hide');
  }

  async changeMicrophone(){
    await this.stopAudio();

    //await this.setAudioTrack();
    await this.publishAudio(false);
  }

  async changeCamera(){
    await this.stopVideo();
    await this.publishVideo(false);
  }

  showHideChat(){
    this.chatopen = !this.chatopen;
    this.setCentreDivWidth();
    this.chatOpenedClosedSubject.next(this.chatopen);
  }

  async toggleHandRaised(){
    if(this.meetingHandler.handraised){
      await this.meetingHandler.dropHand();
    }
    else{
      await this.meetingHandler.raiseHand();
    }
    //this.handraised = !this.handraised;
  }

  async dropParticipantHand(participant: Participant){
    await this.meetingHandler.dropHand(participant.socketid);
  }

  setCentreDivWidth(){
    if(this.util.mobile() && this.focusedMode){
      this.centredivwidth = '100%';
      this.centredivheight = 'auto';
    }
    else{

    

      let widthstr = 'calc(100vw';
      if(this.meetingHandler.participantsMap.size > 0){
        widthstr += ' - min(300px, max(120px, 20vw))';
      }
      if(this.chatopen){
        widthstr += ' - min(400px, 100%)';
      }
      widthstr += ")";
      this.centredivwidth = widthstr;
      this.centredivheight = null;
    }
    this.calculateGridModeDimensions();
  }

  private calculateGridModeDimensions() {
    let gridModeCols;
    let gridModeRows;
    let numParticipants = this.meetingHandler.participantsMap.size + 1
    switch (numParticipants) {
      case 0:
      case 1:
        gridModeCols = 1;
        gridModeRows = 1;
        break;
      case 2:
        gridModeCols = 2;
        gridModeRows = 1;
        break;
      case 3:
      case 4:
        gridModeCols = 2;
        gridModeRows = 2;
        break;
      case 5:
      case 6:
        gridModeCols = 3;
        gridModeRows = 2;
        break;
      case 7:
      case 8:
        gridModeCols = 4;
        gridModeRows = 2;
        break;
      case 9:
        gridModeCols = 4;
        gridModeRows = 3;
        break;
      default:
        gridModeCols = 4;
        gridModeRows = 3;
        break;
    }

    const aspectratio = window.innerWidth / window.innerHeight;

    if(aspectratio > 1){//if landscape
      if(aspectratio > 3 && gridModeRows > 1){//if div height is too narrow, then remove one row
        gridModeRows -= 1;
      }
      
      if(numParticipants > 9){
        gridModeRows = 3.2;//making it 3.2 to make sure part of the lower participant shows so that the user knows there are other participants
      }
    }
    else{
      const temp = gridModeCols;
      gridModeCols = gridModeRows;
      gridModeRows = temp;

      gridModeCols = 1;

      if(numParticipants > 2){
        gridModeRows = 2.2;//making it 2.2 to make sure part of the lower participant shows so that the user knows there are other participants
      }
      // if(gridModeRows > 2){
      //   gridModeRows = 2.2;//making it 2.2 to make sure part of the lower participant shows so that the user knows there are other participants
      // }
      // if(1 / aspectratio > 2){
      //   // if(gridModeCols > 2){
      //   //   gridModeCols = 2;
      //   // }
        
      // }
    }

    let widthpercentage = (100 / gridModeCols);
    let heightpercentage = (100 / gridModeRows);
    this.gridModeWidth = `${widthpercentage}%`;
    this.gridModeHeight = `calc(${heightpercentage}% - 11px)`;

    
    
      // gridModeRows -= 1;

      // widthpercentage = (100 / gridModeCols);
      // heightpercentage = (100 / gridModeRows);
      // this.gridModeWidth = widthpercentage + '%';
      // this.gridModeHeight = heightpercentage + '%';
    
  }

  // getParticipantStyle(participant: Participant){
  //   let widthstr = 'calc(100vw';
  //   if(this.participantsMap.size > 1){
  //     widthstr += ' - min(300px, max(120px, 20vw)';
  //   }
  //   if(this.chatopen){
  //     widthstr += ' - min(400px, 100%)';
  //   }
  //   widthstr += ")";
  //   //this.centredivwidth = widthstr;

  //   return {
  //     order: participant.order,
  //     width: participant.order == 1 ? widthstr : ''
  //   }
  // }

  async setVideoQuality(participantVideoComponent: ParticipantVideoComponent) {
    if(participantVideoComponent && participantVideoComponent.videoConsumer && !participantVideoComponent.videoConsumer.closed){
      let layer;
        let priority;
      if(this.focusedMode){
        if (participantVideoComponent.participant.order == 1 && !this.meetingHandler.screenShareAvailable && !this.meetingHandler.participantScreenShareAvailable) {
          //switch to the highest quality
          layer = 2;
          //set priority to highest
          priority = 254;
        }
        else {
          //switch to the lowest quality
          layer = 0;
          //set priority to low
          priority = 127;
        }
      }
      else{
        if(this.meetingHandler.participantsMap.size > 2){
          layer = 1;
        }
        else{
          layer = 2;
        }
        priority = 127;
      }
      
      if(participantVideoComponent.streamPreferredSpatialLayer != layer){
        participantVideoComponent.streamPreferredSpatialLayer = layer;
        this.meetingHandler.setPreferredConsumerSpatialLayer(participantVideoComponent.participant.socketid, participantVideoComponent.videoConsumer.id, layer);
      }

      if(participantVideoComponent.streamPriority != priority){
        participantVideoComponent.streamPriority = priority;
        this.meetingHandler.setConsumerPriority(participantVideoComponent.participant.socketid, participantVideoComponent.videoConsumer.id, priority);
      }
    }
  }
  

  handleVisibleVideoConsumers(){
    if(!this.meetingHandler.rxdbDb){
      return;
    }
    let order1Participant;
    for(let participantVideoComponent of this.participantVideoComponents){
      if(participantVideoComponent.participant.order == 1){
        order1Participant = participantVideoComponent.participant;
      }
      if(participantVideoComponent && participantVideoComponent.video){
        const video: any = participantVideoComponent.video;
        const scrolledintoview = participantVideoComponent.scrolledIntoView(false);
        if(scrolledintoview || participantVideoComponent.participant.order == 1){
          this.setVideoQuality(participantVideoComponent);
          if(participantVideoComponent.videoConsumer && participantVideoComponent.videoConsumer.paused && video.nativeElement.srcObject){
            this.meetingHandler.resumeConsumer(participantVideoComponent.participant.socketid, participantVideoComponent.videoConsumer, true);
          }
          else if(!participantVideoComponent.videoConsumer || participantVideoComponent.videoConsumer.closed || !video.nativeElement.srcObject){
            participantVideoComponent.setStream();
          }
        }
        else{
          if(participantVideoComponent.videoConsumer && !participantVideoComponent.videoConsumer.paused){
            this.meetingHandler.pauseConsumer(participantVideoComponent.participant.socketid, participantVideoComponent.videoConsumer, true);
          }
        }
      }
    }
    if(!order1Participant){//if no participant is currently set to order 1, then set the first host you find to order 1
      for(let participantVideoComponent of this.participantVideoComponents){
        if(participantVideoComponent.participant.host){
          participantVideoComponent.participant.order = 1;
          order1Participant = participantVideoComponent.participant;
          break;
        }
      }

      if(!order1Participant && this.participantVideoComponents.length > 0){
        this.participantVideoComponents.first.participant.order = 1;
      }
    }
  }

  goToDefaultMode(){
    if(this.util.mobile()){
      this.switchLayout(false, false);//mobile should always default to grid mode
    }
    else{
      if(this.meetingHandler.meetinginfo.mode == this.util.focusmode || this.meetingHandler.cloudRecorder){
        this.switchLayout(true, false);
      }
      else{
        this.switchLayout(false, false);
      }
    }
  }

  toggleLayout(){
    this.switchLayout(!this.focusedMode, false);
  }

  switchLayout(focused: boolean, auto: boolean){
    if(auto && focused && this.util.mobile()){
      //we don't want to automatically switch to focused mode on mobile
      return;
    }
    if(!auto){
      this.focusedModeBeforeAutoswitch = focused;
    }
    
    this.focusedMode = focused;

    console.log('switching layout: ', this);

    if(this.util.mobile()){
      if(focused){
        this.util.enterFullscreen().then(() => {
          this.util.enterLandscape().then(() => {
            this.landscapeEnteredAutomatically = true;
          });
        });
      }
      else{
        if(this.landscapeEnteredAutomatically){
          this.util.exitLandscape();
        }
      }
    }
  }

  restoreFromAutoLayoutSwitch(){
    this.switchLayout(this.focusedModeBeforeAutoswitch, true);
  }

  allParticipantsVisible(){
    for(let participantVideoComponent of this.participantVideoComponents){
      if(participantVideoComponent && participantVideoComponent.video){
        const video: any = participantVideoComponent.video;
        const scrolledintoview = participantVideoComponent.scrolledIntoView(true);
        if(!scrolledintoview){
          return false;
        }
      }
    }
    return true;
  }

  shiftParticipantUp(participant: Participant, newOrder: number) {
    if(participant.order <= newOrder){
      return;
    }
    for (let [key, value] of this.meetingHandler.participantsMap) {
      //any participant who's order is at the new order or after it, and who's order is also before his current order should be moved down
      if (value.order >= newOrder && value.order < participant.order && participant != value) {
        value.order++;
      }
    }
    participant.order = newOrder;
  }

  async muteAllParticipants(){
    await this.meetingservice.setMuteAllParticipants(this.meetingHandler.meetingid, this.meetingHandler.jwt).toPromise();
    await this.meetingHandler.pauseAllOtherAudioProducers();
    // for(let [socketid, participant] of this.participantsMap){
    //   if(participant && participant.participantAudioComponent && participant.participantAudioComponent.audioConsumer){
    //     this.pauseParticipantProducer(socketid, participant.participantAudioComponent.audioConsumer.producerId, 'audio');//no need to await this
    //   }
    // }
    this.toastr.success('All Participants Muted');
  }

  async keepScreenOn(){
    try {
      const _navigator: any = navigator;
      this.wakeLock = await _navigator.wakeLock.request("screen");
      this.wakeLock.addEventListener('release', () => {
        //alert('Wake lock released');
      });
      //alert('Wake lock aquired');
      //statusElem.textContent = "Wake Lock is active!";
    } catch (err) {
      //alert(err);
      console.error(err);
      // The Wake Lock request has failed - usually system related, such as battery.
      //statusElem.textContent = `${err.name}, ${err.message}`;
    }
  }

  // breakOut(){
    
  // }
  get recording(){
    return this.localRecordingService.recording;
  }

  get recordingFilename(){
    return this.localRecordingService.recordingFileHandle?.name;
  }

  async selectRecordingSaveLocation(){
    await this.localRecordingService.aquireRecordingFileHandle();
    
  }

  async recordMeeting(){
    if(this.recording){
      throw new Error('Already recording');
    }
    this.localRecordingEventsService.recordingProcessingProgress = 0;
    $('#recordMeetingModal').modal('show');
  }

  async startRecording(){
    await this.localRecordingService.aquireScreenShareStream();
    if(!this.meetingHandler.cloudRecorder){
      $('#recordMeetingModal').modal('hide');
    }
    await this.localRecordingService.recordMeeting();
  }

  endRecordMeeting(gracefuly: boolean){
    this.localRecordingService.endRecordMeeting(gracefuly);
  }
  
  // dialog(caption: string, body: string, buttons: DialogButtonInfo[]){

  // }

  confirm(yesCallback: any, noCallback?: any, caption: string = 'Are you sure?', body: string = '', yesButtonStyle = 'btn-primary', noButtonStyle = 'btn-secondary', yesText = 'Yes', noText = 'No'){
    //$('#confirmModal').modal('hide');
    this.confirmModalYesCallback = yesCallback;
    this.confirmModalNoCallback = noCallback;
    this.confirmModalCaption = caption;
    this.confirmModalBody = body;
    this.confirmModalYesButtonStyle = yesButtonStyle;
    this.confirmModalNoButtonStyle = noButtonStyle;
    this.confirmModalYesText = yesText;
    this.confirmModalNoText = noText;
    $('#confirmModal').modal('show');
    
  }

  confirmModalYes(){
    $('#confirmModal').modal('hide');
    if(this.confirmModalYesCallback){
      this.confirmModalYesCallback();
    }
    else{
      console.log('no yes callback');
    }
  }

  confirmModalNo(){
    $('#confirmModal').modal('hide');
    if(this.confirmModalNoCallback){
      this.confirmModalNoCallback();
    }
    else{
      console.log('no no callback');
    }
  }

  closeEndMeetingModal(){
    $('#endMeetingModal').modal('hide');
  }

  addLabelNotification(notification: string){
    if(this.labelNotificationVisibleTimeout){
      clearTimeout(this.labelNotificationVisibleTimeout);
      this.labelNotificationVisibleTimeout = null;
    }

    if(this.labelNotificationInvisibleTimeout){
      clearTimeout(this.labelNotificationInvisibleTimeout);
      this.labelNotificationInvisibleTimeout = null;
    }
    
    this.labelNotification.notification = notification;

    // const notificationObj = {notification, visible: false};
    // this.labelNotifications.push(notificationObj);
    
    this.labelNotificationVisibleTimeout = setTimeout(() => {
      //trigger the animation to visible
      this.labelNotification.visible = true;
    });
    
    this.labelNotificationInvisibleTimeout = setTimeout(() => {
      //after the timeout, make it invisible
      this.labelNotification.visible = false;
    }, this.labelNotificationTimeout);
  }
  loadNotificationSounds(){
    this.userJoinedAudio = new Audio();
    this.userJoinedAudio.src = '/assets/audio/user_joined.mp3';
    this.userJoinedAudio.load();

    this.networkLostAudio = new Audio();
    this.networkLostAudio.src = '/assets/audio/networkerror.mp3';
    this.networkLostAudio.load();

    this.lastUserLeftAudio = new Audio();
    this.lastUserLeftAudio.src = '/assets/audio/userleft.mp3';
    this.lastUserLeftAudio.load();
  }
}



