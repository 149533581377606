<router-outlet></router-outlet>

<div class="modal" tabindex="-1" role="dialog" id="recordingModal" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Saving recording to {{this.recordingFilename}}</h5>
      </div>
      <div class="modal-body">
        <p>Please wait while we process your recording...{{this.recordingProcessingProgress}}%</p>
      </div>
    </div>
  </div>
</div>