<div class="participantcontainer" #container>
    <div class="participantvideodiv">
        <div class="participantmediacontainer">
            <div class="handraiseddiv">
                <span *ngIf="!this.production">{{this.scrolledIntoView(true) ? 'Completely Visible' : this.scrolledIntoView(false) ? 'Partially Visible' : 'Not Visible'}}</span>
                <button *ngIf="participant.handRaised" class="handraisedbutton fa-stack" (click)="dropHand()" title="Drop Hand">
                    <i class="fas fa-hand-paper"></i>
                    <!-- <i class="fas fa-times"></i> -->
                </button>
            </div>
            <video class="participantvideo" playsinline autoplay muted #video [ngStyle]="{'display': participant.videoAvailable ? 'block': 'none'}" [ngClass]="{'localparticipant': this.belongsToLocalParticipant}"></video>
            <div class="participantbignamediv" [ngStyle]="{'display': !participant.videoAvailable ? 'flex': 'none'}">
                <span class="participantbignamespan" [attr.title]="participant.name">{{participant.name}}</span>
            </div>
            <!-- <div class="participantphotodiv" [ngStyle]="{'display': !participant.videoAvailable ? 'block': 'none'}">
                <img [src]="participant.photourl || util.defaultphotourl" class="participantphoto" >
            </div> -->
        </div>
        <!-- <audio #audio autoplay></audio> -->
        <div class="videosettingsdiv" *ngIf="!participant.me">
            <button *ngIf="settings.host" class="disconnectuserbutton fas fa-user-minus" (click)="initiateDisconnectUser()"></button>
            <button *ngIf="settings.host && this.meetingHandler.meetinginfo.mode == this.util.focusmode && !participant.host" class="unlockparticipantbutton fas" [ngClass]="{'fa-unlock': participant.unlocked, 'fa-lock': !participant.unlocked, mediadisabled: !participant.unlocked}" (click)="toggleUserLocked()"></button>
            <button [disabled]="!settings.host" class="enableaudiobutton fa" [ngClass]="[participant.audioAvailable ? 'null' : 'mediadisabled', participant.audioAvailable ? 'fa-microphone' : 'fa-microphone-slash']" (click)="toggleAudioEnabled()"></button>
            <button *ngIf="settings.host" [disabled]="!settings.host" class="enablevideobutton fa" [ngClass]="[participant.videoAvailable ? 'null' : 'mediadisabled', participant.videoAvailable ? 'fa-video' : 'fa-video-slash']" (click)="toggleVideoEnabled()"></button>
        </div>
        <div #volumeMeter class="volume-meter"></div>
        <div [hidden]="!participant.videoAvailable" class="participantnamediv" [attr.title]="production ? participant.name : participant.socketid + ' | ' + participant.username + ' | ' + participant.name" >
            <span *ngIf="!production"><span class="usernamespan">
              {{participant.socketid}}
              |
              {{participant.username}}</span> | </span>
            <span class="namespan">{{participant.name}}</span>
        </div>
    </div>
</div>
