import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingService } from 'src/app/services/meeting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
 
  user:any = {};
  meetings:any = [];
  constructor(private router: Router, private activatedroute: ActivatedRoute, private meetingService: MeetingService, private toastr:ToastrService) {
    
   }

  ngOnInit(): void {
    //debugger;
    if(this.meetingService.userLoggedIn()){
      this.user = JSON.parse(localStorage.getItem(`vCirlce-User`));
      this.getMeetings();
    }
    else{
      this.router.navigate(['/login']);
    }
  }

  async getMeetings(){
      this.meetings = await this.meetingService.getMeetingsLite(this.user.jwt).toPromise();
  }

  async joinMeeting(meetingid:string){
    let auth :any = await this.meetingService.getUserToken(meetingid,this.user.jwt).toPromise();
    return this.router.navigate([`/join/${meetingid}`], { queryParams: { auth: auth.jwt } });
  }

  createMeeting(){
    return this.router.navigate(['/create-meeting'])
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Link copied');
  }
}